import { useEffect, useRef, useState } from "react";
import { Dialog } from "@headlessui/react";
import { CodeGroup, Note } from "../../documents";
import ExportDocumentModal from "./exportDocumentModal";
import { ModalWrapper } from "../../common/modalWrapper";
import CloudResourceService from "../../../app/service/cloudResourceService";
import { toast } from "react-toastify";
import Spinner from "../../../app/spinner/spinner";
interface Props {
  show: boolean;
  closeModal: (refresh?: boolean) => void;
  subscriptionList: SubscriptionItem[];
}
export default function InvoiceImportModal(props: Readonly<Props>) {
  const [open, setOpen] = useState(props.show);
  const [showExportDocumentModal, setShowExportDocumentModal] =
    useState<boolean>(false);

  const [selectedSubscription, setSelectedSubscription] = useState<string>("");
  const [regions, setRegions] = useState<any>([]);
  const [loadingRegion, setLoadingRegion] = useState<boolean>(false);
  const [selectedRegion, setSelectedRegion] = useState<string>('');

  const cancelButtonRef = useRef(null);

  const [uri, setUri] = useState<string>("");

  useEffect(() => {
    setOpen(props.show);
    setUri(
      `https://${process.env.REACT_APP_API_DOMAIN}/ref/finops-hub/azuredeploy.json`
    );
  }, [props?.show]);

  const closeModal = (refresh?: boolean) => {
    setOpen(false);
    props.closeModal(refresh);
  };

  const openExportDocumentModal = () => {
    setShowExportDocumentModal(true);
  };

  const closeExportDocumentModal = () => {
    setShowExportDocumentModal(false);
  };

  const getRegions = (id: string) => {
    setLoadingRegion(true);
    CloudResourceService.listAvailableRegionsBySubscription(id)
      .then((response: any) => {
        setLoadingRegion(false);
        const result = response?.data?.result?.value || [];
        setRegions(result);
      })
      .catch((e: any) => {
        setLoadingRegion(false);
        toast.error(`${e?.response?.data?.message}`);
      });
  };

  const onChangeSubscriptions = (id: string) => {
    setSelectedSubscription(id);
    if(id) {
      getRegions(id);
    } else {
      setRegions([]);
      setSelectedRegion('');
    }
  }

  return (
    <>
      <ExportDocumentModal
        show={showExportDocumentModal}
        closeModal={() => closeExportDocumentModal()}
      />

      <ModalWrapper
        open={open}
        cancelButtonRef={cancelButtonRef}
        closeModal={closeModal}
      >
        <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full md:max-w-2xl lg:max-w-5xl sm:p-6">
          <div>
            <div className="mt-3 sm:mt-5">
              <Dialog.Title
                as="h3"
                className="text-base text-center font-semibold leading-6 text-gray-900"
              >
                Invoice Import
              </Dialog.Title>
              <div className="mt-2 p-4">
                <p className="my-2 text-sm text-gray-700">
                  This process will deploy the invoice and usage pre processor.
                </p>
                <div className="mt-4 mb-12">
                  <h3>Option A:</h3>

                  <Note type="info">
                    Ensure to deploy this on 1 subscription to handle the entire
                    tenant.
                  </Note>
                  <p className="text-sm text-gray-700">
                    Name the hub floatfinops-hub
                  </p>
                  <p className="text-sm text-gray-700 mb-2">
                    Tag: App:FloatFinOps
                  </p>
                  <a href="https://aka.ms/finops/hubs/deploy" target={"_blank"}>
                    <img
                      src="https://raw.githubusercontent.com/Azure/azure-quickstart-templates/master/1-CONTRIBUTION-GUIDE/images/deploytoazure.svg?sanitize=true"
                      alt="Deploy To Azure"
                    />
                  </a>
                </div>

                <div className="my-12">
                  <h3>Option B:</h3>

                  <div>
                    <label
                      htmlFor="subscription"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Subscription
                    </label>
                    <div className="mt-2">
                      <select
                        id="Subscription"
                        name="Subscription"
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        value={selectedSubscription}
                        required
                        onChange={(e) =>
                          onChangeSubscriptions(e?.target?.value)
                        }
                      >
                        <option value={""} key={"option1"}>
                          ---Choose Subscription---
                        </option>
                        {(props?.subscriptionList || [])?.map(
                          (subscription) => (
                            <option
                              value={subscription.subscriptionId}
                              key={subscription.subscriptionId}
                            >
                              {subscription.subcriptionName}
                            </option>
                          )
                        )}
                      </select>
                    </div>
                  </div>
                  <div className="mb-4">
                    <label
                      htmlFor="subscription"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Location
                    </label>
                    <div className="mt-2">
                      {loadingRegion ? (
                        <div className="leading-6 h-9">
                        <Spinner show={loadingRegion} isPrimary={true} />
                        </div>
                      ) : (
                        <select
                          id="Location"
                          name="Location"
                          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          value={selectedRegion}
                          required
                          onChange={(e) => setSelectedRegion(e?.target?.value)}
                        >
                          <option value={""} key={"option2"}>
                            ---Choose Location---
                          </option>
                          {(regions || [])?.map((region: any) => (
                            <option value={region.name} key={region.name}>
                              {region.displayName}
                            </option>
                          ))}
                        </select>
                      )}
                    </div>
                  </div>

                  <CodeGroup
                    title="Azure CLI"
                    code={`az account set --subscription ${
                      selectedSubscription || "{SUBSCRIPTION}"
                    }\n\naz provider register --namespace Microsoft.CostManagementExports\n\naz provider register --namespace Microsoft.EventGrid\n\naz group create --name rg-floatfinops --location ${
                      selectedRegion || "{LOCATION}"
                    }\n\naz deployment group create -g rg-floatfinops --name float-finops-hub--template-uri ${uri} --parametershubName=floatfinops-hub --verbose\n\naz deployment sub create --name Float-FinOps-Analyst-HUB --location ${
                      selectedRegion || "{LOCATION}"
                    } --template-uri https://ffglasshostingprod.blob.core.windows.net/glass-web-prod/ref/lighthouse/lighthouse-ff-hub-focus-manager.json --verbose`}
                  >
                    <span>
                      <p>
                        {`az account set --subscription ${
                          selectedSubscription || "{SUBSCRIPTION}"
                        }`}
                      </p>
                      <br />
                      <p>
                        az provider register --namespace
                        Microsoft.CostManagementExports
                      </p>
                      <br />
                      <p>
                        az provider register --namespace Microsoft.EventGrid
                      </p>
                      <br />
                      <p>
                        {`az group create --name rg-floatfinops --location ${
                          selectedRegion || "{LOCATION}"
                        }`}
                      </p>
                      <br />
                      <p>
                        {`az deployment group create -g rg-floatfinops --name float-finops-hub--template-uri ${uri} --parametershubName=floatfinops-hub --verbose`}
                      </p>
                      <br />
                      <p>
                        {`az deployment sub create --name Float-FinOps-Analyst-HUB --location ${
                          selectedRegion || "{LOCATION}"
                        } --template-uri https://ffglasshostingprod.blob.core.windows.net/glass-web-prod/ref/lighthouse/lighthouse-ff-hub-focus-manager.json --verbose`}
                      </p>
                    </span>
                  </CodeGroup>

                  <button
                    type="button"
                    className="rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    onClick={() => openExportDocumentModal()}
                  >
                    Configure Export
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
            <button
              type="button"
              className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-2 sm:mt-0"
              onClick={() => closeModal()}
              ref={cancelButtonRef}
            >
              Cancel
            </button>
          </div>
        </Dialog.Panel>
      </ModalWrapper>
    </>
  );
}
