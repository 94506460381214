import { jwtDecode } from "jwt-decode";
import moment from "moment-timezone";
import { NavigateFunction } from "react-router-dom";
import { toast } from "react-toastify";
import AuthenticationService from "./authService";
import { AccountInfo } from "@azure/msal-common";

const showMessage = (result: any): void => {
  const msg = result["message"];
  if (result?.isSuccess) {
    toast.success(msg);
  } else {
    const exceptionMessage = result["exceptionMessage"];
    if (exceptionMessage) {
      toast.error(
        <div className="ml-3 flex-1">
          <p className="text-sm font-medium text-gray-900">{msg}</p>
          <p className="mt-1 text-sm text-gray-500">{exceptionMessage}</p>
        </div>
      );
    } else {
      toast.error(msg);
    }
  }
};

const dbkProfile = {
  name: "Float Cloud FinOps",
  imgUrl:
    "https://dbkdevstorage.blob.core.windows.net/logo/float-cloud-finops-logo.png",
};

const passwordPattern =
  /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/;

const companySizeList: Array<{ name: string; value: string }> = [
  { name: "1-10", value: "1-10" },
  { name: "11-50", value: "11-50" },
  { name: "51-100", value: "51-100" },
  { name: "101-500", value: "101-500" },
  { name: "500+", value: "500+" },
];

const userDetail = (): User => {
  return JSON.parse(localStorage.getItem("userDetail") ?? "{}") || {};
};

const currentUser = (): User => {
  return JSON.parse(localStorage.getItem("currentUser") ?? "{}") || {};
};

const profileImage = (): string => {
  return localStorage.getItem("profileImage") ?? "";
};

const users = {
  allUsers: [],
};

const accounts: AccountItem[]  = [];

const getAllUsers = () => {
  AuthenticationService.getAllUsers().then((response: any) => {
    users.allUsers = response?.data?.result || [];
  });
};

function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ");
}

const currentDate = () => {
  return new Date().toJSON().slice(0, 10);
};

const timeAgo = (date: any) => {
  const seconds = Math.floor(
    ((new Date() as any) - (new Date(date + "Z") as any)) / 1000
  );

  let interval = Math.floor(seconds / 31536000);
  if (interval > 1) {
    return interval + " years ago";
  }

  interval = Math.floor(seconds / 2592000);
  if (interval > 1) {
    return interval + " months ago";
  }

  interval = Math.floor(seconds / 86400);
  if (interval > 1) {
    return interval + " days ago";
  }

  interval = Math.floor(seconds / 3600);
  if (interval > 1) {
    return interval + " hours ago";
  }

  interval = Math.floor(seconds / 60);
  if (interval > 1) {
    return interval + " minutes ago";
  }

  if (seconds < 10) return "just now";

  return Math.floor(seconds) + " seconds ago";
};

const getDate = (d?: any) => {
  let date = new Date();
  if(d) {
    date = new Date(d);
  }
    const year = date.getFullYear(),
    month = (date.getMonth() + 1).toString(),
    formatedMonth = month.length === 1 ? "0" + month : month,
    day = date.getDate().toString(),
    formatedDay = day.length === 1 ? "0" + day : day;
  return year + "-" + formatedMonth + "-" + formatedDay;
};

const getPrevMonthDate = () => {
  const date = new Date();
  const month = date.getMonth();
  const formatPrevMonth = new Date(date.setMonth(month - 1));
return getDate(formatPrevMonth);
}

const isValidEmail = (email: string): boolean => {
  const validRegex =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  return !!validRegex?.exec(email);
};

let planId = "";

const fixDecimal = (val: number, range?: number) => {
  const decimalRange = range ?? 2;
  const numberValue = (val || 0)?.toString();
  return parseFloat(numberValue).toFixed(decimalRange);
};

enum stepStatus {
  CURRENT = "current",
  UPCOMING = "upcoming",
  COMPLETE = "complete",
}

const coldColors500 = [
  "#3B82F6", // blue-500
  "#22D3EE", // cyan-500
  "#9333EA", // fuchsia-500
  "#6EE7B7", // green-500 (this is a cooler green)
  "#9333EA", // indigo-500
  "#3B82F6", // lightBlue-500
  "#7DD3FC", // sky-500
  "#818CF8", // violet-500 (cool purple)
];
const maturityList: string[] = ["Crawl", "Walk", "Run"];

const phaseList: string[] = ["Inform", "Optimize", "Operate"];

let selectedProject = "";

enum saleStatus {
  CREATED = "Created",
  CANCELLED = "Cancelled",
  INVOICE_UPLOADED = "InvoiceUploaded",
  INVOICE_REJECTED = "InvoiceRejected",
  ACCESS_REVIEW = "AccessReview",
  PROPOSAL = "Proposal",
  COMPLETED = "Completed",
}

const timeZones = (): string[] => {
  return moment.tz.names() || [];
};

const currentTimeZone = (): string => {
  return moment.tz.guess() || "";
};

enum accountSalesProposalStatus {
  Created = "Created",
  Submitted = "Submitted",
  Approved = "Approved",
  Rejected = "Rejected",
  ChangeRequest = "ChangeRequest",
}

enum accountSalesStatus {
  Created = "Created",
  InvoiceUploaded = "InvoiceUploaded",
  InvoiceRejected = "InvoiceRejected",
  Proposal = "Proposal",
  Completed = "Completed",
  Cancelled = "Cancelled",
  AccessReview = "AccessReview",
}

let accountData: { accounts: AccountItem[] } = { accounts: [] };

const setAccounts = (data: AccountItem[]) => {
  accountData.accounts = data || [];
};

const getAccounts = (): AccountItem[] => {
  return accountData?.accounts || [];
};

function convertAccountInfoToCurrentUserItem(accountInfo: AccountInfo): any {
  return {
      id: accountInfo.localAccountId,
      accountId: "",
      firstName: accountInfo.name?.split(" ")[0],
      lastName: accountInfo.name?.split(" ")[1],
      username: accountInfo.username,
      token: accountInfo.idToken
  };
}

function convertAccountInfoToAccountItem(accountInfo: AccountInfo): AccountItem {
  return {
      id: accountInfo.homeAccountId,
      parentId: "",
      name: accountInfo.name || accountInfo.username,
      website: "",
      size: "",
      mATenantId: accountInfo.tenantId,
      isActive: true,

      users: [],
      saleStatus: "",
      type: "",

      proposals: [],
      parentName: "",
  };
}

function convertAccountInfoArrayToAccountItemArray(accountInfoArray: AccountInfo[]): AccountItem[] {
  return accountInfoArray.map(convertAccountInfoToAccountItem);
}


let redirectPath = "";

let selectedAccount: any = { id: null };
let authVersion: VersionHealthCheck | any = {};
let portalVersion: VersionHealthCheck | any = {};

const checkOnBoardProcess = (
  response: any,
  navigate: NavigateFunction,
  fromLogin?: boolean,
  path?: string
) => {
  const result: User = response?.data?.result;
  localStorage.setItem("userDetail", JSON.stringify(result || ""));
  if (!result?.isVerified) {
    navigate("/verifyToken");
  } else {
    const account: AccountItem | undefined = (result?.accounts || []).find(
      (x) => x.id === (commonService.selectedAccount.id || result?.accountId)
    );
    checkSalesStatus(account, navigate, fromLogin, path);
  }
};

const checkSalesStatus = (
  account: AccountItem | undefined,
  navigate: NavigateFunction,
  fromLogin?: boolean,
  path?: string
) => {
  if (account?.type === "Customer") {
    if (
      account?.saleStatus === commonService.saleStatus.CREATED ||
      account?.saleStatus === commonService.saleStatus.CANCELLED
    ) {
      parseNavigate("/newAccount", navigate);
    } else if (
      account?.saleStatus === commonService.saleStatus.INVOICE_UPLOADED ||
      account?.saleStatus === commonService.saleStatus.INVOICE_REJECTED
    ) {
      parseNavigate("/newAccountProsessing", navigate);
    } else if (account?.saleStatus === commonService.saleStatus.ACCESS_REVIEW) {
      parseNavigate("/accessReview", navigate);
    } else if (account?.saleStatus === commonService.saleStatus.PROPOSAL) {
      parseNavigate("/newAccountPotential", navigate);
    } else if (account?.saleStatus === commonService.saleStatus.COMPLETED) {
      parseNavigate(path ?? "/", navigate);
    }
  } else if (fromLogin) {
    parseNavigate(path ?? "/", navigate);
  }
};

const parseNavigate = (url: string, navigate: NavigateFunction) => {
  navigate(`${url}?customerId=${getAccountId()}`);
};

const sizePipe = (size: string) => {
  const parsedSize = size?.replace(/_/g, " ") || "";
  return parsedSize;
};

const isValidToken = (navigate: NavigateFunction) => {
  // console.log("check isValidToken ")
  // const token = useToken();

  return true;
  // const currentUser =
  //   JSON.parse(localStorage.getItem("currentUser") ?? "{}") || {};
  // let token = currentUser?.token;
  // if (token) {
  //   let decodedToken: any = jwtDecode(token) || null;
  //   const currentDate = new Date();
  //   if (decodedToken?.exp * 1000 < currentDate.getTime()) {
  //     console.log("Token expired.");
  //     navigate("/login");
  //   } else {
  //     return true;
  //   }
  // }
};

const getAccountId = () => {
  return selectedAccount?.id || userDetail()?.accountId || currentUser()?.accountId || "";
};

const getPercentageChange = (oldNumber: number, newNumber: number) => {
  if (oldNumber === 0 || oldNumber === newNumber) return "0%";
  const decreaseValue = oldNumber - newNumber;
  return Math.round((decreaseValue / oldNumber) * 100) + "%";
};

const formatCurrency = (
  value: number,
  currency: string = "USD",
  locale: string = "en-US"
): string => {
  return new Intl.NumberFormat(locale, {
    style: "currency",
    currency: currency,
  }).format(value);
};

const getDateString = (value: string) => {
  return new Date(value || "").toDateString();
};

const parseCount = (value: number) => {
  return value ? parseFloat(parseFloat(value.toString()).toFixed(3)) : 0;
};

const parseBytes = (value: number) => {
  const totalBytesInMB = value ? value / Math.pow(1024, 2) : 0;
  return parseCount(totalBytesInMB);
};

const getChartData = (
  vmMetric: any,
  range: string,
  key: string,
  hasBytes?: boolean
) => {
  const timeSeriesData = vmMetric.timeSeries[0]?.values || [];
  let count = 0;
  const arr: any[] = [];
  timeSeriesData.forEach((t: any) => {
    const currentDate = new Date(t?.timeStamp).toLocaleString();
    count = t[range] || 0;
    const data: any = { date: currentDate };
    data[key] = hasBytes ? parseBytes(count) : parseCount(count);
    arr.push(data);
  });
  return arr || [];
};

const getChartData2 = (
  vmMetric: any,
  vmMetric2: any,
  range: string,
  key1: string,
  key2: string,
  hasBytes?: boolean
) => {
  const timeSeriesData = vmMetric.timeSeries[0]?.values || [];
  let count1 = 0;
  let count2 = 0;
  const arr: any[] = [];
  timeSeriesData.forEach((t: any, i: number) => {
    const currentDate = new Date(t?.timeStamp).toLocaleString();
    count1 = t[range] || 0;
    count2 = vmMetric2[i][range] || 0;
    const data: any = {
      date: currentDate,
    };
    data[key1] = hasBytes ? parseBytes(count1) : parseCount(count1);
    data[key2] = hasBytes ? parseBytes(count2) : parseCount(count2);
    arr.push(data);
  });
  return arr || [];
};

const onInputChange = (
  fieldName: string,
  value: string,
  modelVal: any,
  error: any
) => {
  const field = error[fieldName] || {};
  field.hasError = !value;
  const model = JSON.parse(JSON.stringify(modelVal));
  model[fieldName] = value;
  error[fieldName] = field;
  return { error: error, model: model };
};

const checkError = (errorState: any, model: any) => {
  let isError = false;
  for (const key of Object.keys(errorState) || []) {
    if (errorState[key]?.required && !model[key]) {
      isError = true;
    }
  }
  return isError;
};

const isNegativeChange = (oldNumber: number, newNumber: number) => {
  if (oldNumber === newNumber) return null;
  return !commonService.getPercentageChange(oldNumber, newNumber).includes("-");
};

const formatToUSCurrency = (value: number) => {
  return currencyFormatter(value, "USD");
};

const currencyFormatter = (
  value: number | string,
  currency: string
): string => {
  const numberValue = typeof value === "string" ? parseFloat(value) : value;
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: currency,
    minimumFractionDigits: 0,
  }).format(numberValue);
};

const hasFieldError = (error: any, fieldName: string) => {
  return (error || {})?.[fieldName]?.hasError;
};

const showResponseError = (e: any) => {
  toast.error(`${e?.response?.data?.message}`);
}

const initProjectItem = (): ProjectItem => {
  return {
      id: "",
      accountId: "",
      name: "",
      description: "",
      ownerId: "",
      maturity: "",
      phase: "",
      updatedOn: "",
      opportunityPlan: [],
  };
};

const getNextMonthDate = () => {
  const date = new Date();
  date.setMonth(date.getMonth() + 1);
  const year = date.getFullYear(),
    month = (date.getMonth() + 1).toString(),
    formatedMonth = month.length === 1 ? "0" + month : month,
    day = date.getDate().toString(),
    formatedDay = day.length === 1 ? "0" + day : day;
  return year + "-" + formatedMonth + "-" + formatedDay;
};

const planItem = (): OpportunityPlanItem => {
  return {
    id: "",
    accountId: "",
    projectId: "",
    planCode: "",
    name: "",
    description: "",
    value: 0,
    startOn: commonService.getDate(),
    dueOn: getNextMonthDate(),
    opportunityPlanStep: [],
    opportunityPlanContacts: [],
    opportunityPlanActivities: [],
    status: planStatusList[0],
  };
}

const planStatusList: string[] = [
  "Analysis",
  "Pending Approval",
  "Active",
  "Completed",
  "Archive",
];

const stepItem = (): OpportunityPlanStepItem => {
  return {
    id: "",
    accountId: "",
    projectId: "",
    name: "",
    description: "",
    opportunityPlanId: "",
    opportunityPlanResources: [],
  };
}

const isAuthenticated = () => {
  const currentUser =
    JSON.parse(localStorage.getItem("currentUser") ?? "{}") || {};
  let token = currentUser?.token;
  if (token) {
    let decodedToken: any = jwtDecode(token) || null;
    const currentDate = new Date();
    if (decodedToken?.exp * 1000 < currentDate.getTime()) {
      return false;
    } else {
      return true;
    }
  }else{
    return false;
  }
};

const getVersions = () => {
  AuthenticationService.authHealthcheck().then((response: any) => {
    authVersion = response?.data || {};
  });
  AuthenticationService.portalHealthcheck().then((response: any) => {
    portalVersion = response?.data || {};
  });
};

const getCurrencyValue = (value: any, currencyCode?: string) => {
  const currency = new Intl.NumberFormat('en-US', { 
    style: 'currency',
    currency: currencyCode || 'USD',
  });
  
  return currency.format(value || 0);
}

const commonService = {
  showMessage,
  dbkProfile,
  passwordPattern,
  companySizeList,
  userDetail,
  profileImage,
  users,
  getAllUsers,
  classNames,
  currentDate,
  getDate,
  timeAgo,
  isValidEmail,
  planId,
  fixDecimal,
  coldColors500,
  maturityList,
  phaseList,
  selectedProject,
  stepStatus,
  setAccounts,
  getAccounts,
  accountData,
  selectedAccount,
  authVersion,
  portalVersion,
  redirectPath,
  saleStatus,
  checkOnBoardProcess,
  accountSalesProposalStatus,
  accountSalesStatus,
  timeZones,
  currentTimeZone,
  sizePipe,
  isValidToken,
  getAccountId,
  parseNavigate,
  getPercentageChange,
  formatCurrency,
  getDateString,
  parseCount,
  parseBytes,
  getChartData,
  getChartData2,
  onInputChange,
  checkError,
  isNegativeChange,
  formatToUSCurrency,
  hasFieldError,
  showResponseError,
  initProjectItem,
  planItem,
  planStatusList,
  stepItem,
  isAuthenticated,
  getPrevMonthDate,
  getVersions,
  convertAccountInfoArrayToAccountItemArray,
  convertAccountInfoToCurrentUserItem,
  currentUser,
  accounts,
  getCurrencyValue
};

export default commonService;
