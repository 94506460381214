import { Popover, Transition } from "@headlessui/react";
import {
  ChevronDownIcon,
  ChevronUpDownIcon,
  ChevronUpIcon,
  FunnelIcon,
} from "@heroicons/react/24/outline";
import { FunnelIcon as FunnelSolidIcon } from "@heroicons/react/24/solid";
import { Fragment, useEffect, useState } from "react";

interface SortProps {
  sortBy: string;
  sortName: string;
  asc: boolean;
  onSortChange: (sortBy: string, asc: boolean) => void;
}

export function TableSort(props: Readonly<SortProps>) {
  const onSortClick = () => {
    let newAsc;
    if (props?.sortBy !== props?.sortName) {
      newAsc = true;
    } else {
      newAsc = !props?.asc;
    }
    props?.onSortChange(props?.sortName, newAsc);
  };

  return (
    <a onClick={onSortClick} className="cursor-pointer ml-2" href="#">
        {props?.sortBy === props?.sortName ? (
          <>
            {props?.asc ? (
              <ChevronDownIcon className="h-4 w-4" aria-hidden="true" />
            ) : (
              <ChevronUpIcon className="h-4 w-4" aria-hidden="true" />
            )}
          </>
        ) : (
          <ChevronUpDownIcon className="h-5 w-5" aria-hidden="true" />
        )}
      </a>
  );
}

interface HeadProps {
  sortBy: string;
  sortName: string;
  asc: boolean;
  name: string;
  filterVal: string;
  onSortChange: (sortBy: string, asc: boolean) => void;
  onFilterChange: (name: string, filterVal: string) => void;
}

export function TableHead(props: Readonly<HeadProps>) {
  return (
    <div className="flex items-center">
        {props?.name}
        <TableSort
          sortBy={props?.sortBy}
          sortName={props?.sortName}
          asc={props?.asc}
          onSortChange={props?.onSortChange}
        />
        <TableFilter
          filterVal={props?.filterVal}
          name={props?.sortName}
          onFilterChange={props?.onFilterChange}
        />
      </div>
  );
}

interface FilterProps {
  filterVal: string;
  name: string;
  onFilterChange: (name: string, filterVal: string) => void;
}

export function TableFilter(props: Readonly<FilterProps>) {
  const [filterVal, setFilterVal] = useState<string>("");

  useEffect(() => {
    setFilterVal(props?.filterVal || "");
  }, [props?.filterVal]);

  const onInputChange = (val: string) => {
    setFilterVal(val);
  };

  const onFilter = () => {
    props?.onFilterChange(props?.name, filterVal);
  };

  return (
    <Popover className="relative mt-2">
        <Popover.Button className="inline-flex items-center gap-x-1 text-sm font-semibold leading-6 text-gray-900">
          {filterVal ? (
            <FunnelSolidIcon className="h-4 w-4" aria-hidden="true" />
          ) : (
            <FunnelIcon className="h-4 w-4" aria-hidden="true" />
          )}
        </Popover.Button>

        <Transition
          as={Fragment}
          enter="transition ease-out duration-200"
          enterFrom="opacity-0 translate-y-1"
          enterTo="opacity-100 translate-y-0"
          leave="transition ease-in duration-150"
          leaveFrom="opacity-100 translate-y-0"
          leaveTo="opacity-0 translate-y-1"
        >
          <Popover.Panel className="absolute z-10 mt-5 ml-4 mr-4 flex w-60 max-w-max -translate-x-1/2 px-4">
            <div className="w-screen max-w-md flex-auto overflow-hidden rounded-xl bg-white text-sm leading-6 shadow-lg ring-1 ring-gray-900/5">
              <div className="p-4">
                <label htmlFor="name" className="sr-only">
                  Name
                </label>
                <input
                  id="name"
                  name="name"
                  type="text"
                  required
                  value={filterVal}
                  onChange={(e) => onInputChange(e?.target?.value)}
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
              <div className="flex justify-center">
                <button
                  type="button"
                  className="block rounded-md bg-indigo-600 px-2 py-1 mb-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  onClick={onFilter}
                >
                  Filter
                </button>
              </div>
            </div>
          </Popover.Panel>
        </Transition>
      </Popover>
  );
}
