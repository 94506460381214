import { useRef, useState, useEffect, useLayoutEffect } from "react";
import { Dialog } from "@headlessui/react";
import Spinner from "../../../app/spinner/spinner";
import OpportunityService from "../../../app/service/opportunityService";
import { toast } from "react-toastify";
import commonService from "../../../app/service/commonService";
import { ChevronRightIcon } from "@heroicons/react/24/outline";
import ResourceDetialModal from "./resourceDetailModal";
import { ModalWrapper } from "../../common/modalWrapper";
import { StepView } from "../../../app/common/commonControls";
import Multiselect from 'multiselect-react-dropdown';

interface Props {
  show: boolean;
  closeModal?: any;
  planStepId: string;
  allResources: MonthCostCategoryResult[];
  templates: OpportunityPlanResourceTaskTemplateItem[];
  date?: string;
}

const defaultSteps = [
  {
    id: 1,
    name: "Select Resources",
    href: "#",
    status: commonService.stepStatus.CURRENT,
  },
  {
    id: 2,
    name: "Select Template",
    href: "#",
    status: commonService.stepStatus.UPCOMING,
  },
  {
    id: 3,
    name: "Confirmation",
    href: "#",
    status: commonService.stepStatus.UPCOMING,
  },
];

export default function ResourcesModal(props: Readonly<Props>) {
  const [open, setOpen] = useState(props.show);
  const [loading, setLoading] = useState<boolean>(false);
  const all = "All";
  const [subscriptionList, setSubscriptionList] = useState<string[]>([]);
  const [rsGroupList, setRsGroupList] = useState<string[]>([]);
  const [mainCategoryList, setMainCategoryList] = useState<string[]>([]);
  const [subCategoryList, setSubCategoryList] = useState<string[]>([]);

  const [subscription, setSubscription] = useState<string>(all);
  const [rsGroup, setRsGroup] = useState<string>(all);
  const [mainCategory, setMainCategory] = useState<string>(all);
  const [subCategory, setSubCategory] = useState<string>(all);

  const [allResources, setAllResources] = useState<MonthCostCategoryResult[]>(
    props?.allResources || []
  );

  const [selectedResource, setSelectedResource] = useState<
    MonthCostCategoryResult[]
  >([]);

  const checkbox = useRef<any>(null);
  const [indeterminate, setIndeterminate] = useState(false);
  const [checked, setChecked] = useState(false);

  const cancelButtonRef = useRef(null);

  const modalTitle = "Add Resource";

  const [isPageOneValid, setIsPageOneValid] = useState<boolean>(false);
  const [isPageTwoValid, setIsPageTwoValid] = useState<boolean>(false);
  const [isNextButtonDisabled, setIsNextButtonDisabled] = useState<boolean>(false);

  const [steps, setSteps] = useState<any[]>([]);
  const [selectedStep, setSelectedStep] = useState<any>(defaultSteps[0]);
  const [selectedTemplates, setSelectedTemplates] = useState<OpportunityPlanResourceTaskTemplateItem[]>([]);
  const [forceRender, setForceRender] = useState(false);

  useEffect(() => {
    initFilters();
    setOpen(props.show);
    if (props?.show) {
      initStep();
    }
  }, [props.show]);

  useEffect(
    () => setFilter(),
    [subscription, rsGroup, mainCategory, subCategory]
  );

  const initStep = () => {
    setSteps(JSON.parse(JSON.stringify(defaultSteps)));
  };

  useLayoutEffect(() => {
    const isIndeterminate =
      selectedResource.length > 0 &&
      selectedResource.length < allResources.length;
    setChecked(selectedResource.length === allResources.length);
    setIndeterminate(isIndeterminate);
    if (checkbox.current) {
      checkbox.current.indeterminate = isIndeterminate;
    }
    setIsPageOneValid(selectedResource?.length > 0);
  }, [selectedResource]);

  const onTemplatesChange = (selectedList: OpportunityPlanResourceTaskTemplateItem[]) => {
    setIsPageTwoValid(selectedList.length > 0);

    const disableNext = selectedList.some((temp) => 
      temp.value === 0 || temp.value === null || temp.value === undefined || temp.commandParameters.some(
        (item) => item.isRequired && !item.isAutoFilled && (item.value === null || item.value === '' || item.value === undefined)
      ) 
    );
    
    setIsNextButtonDisabled(disableNext);
    setSelectedTemplates(selectedList);
  }

  const handleTemplatesChoice = (selectedList: OpportunityPlanResourceTaskTemplateItem[], selectedItem: OpportunityPlanResourceTaskTemplateItem) => {
    onTemplatesChange(selectedList);
    setForceRender(!forceRender); 
  };
  
  const handleTemplatesRemove = (selectedList: OpportunityPlanResourceTaskTemplateItem[], selectedItem: OpportunityPlanResourceTaskTemplateItem) => {
    onTemplatesChange(selectedList);
    setForceRender(!forceRender); 
  };
  
  const initFilters = () => {
    const sList = [all];
    const rgList = [all];
    const mcList = [all];
    const scList = [all];
    setSubscription(all);
    setRsGroup(all);
    setMainCategory(all);
    setSubCategory(all);
    (props.allResources || []).forEach((x) => {
      if (sList.indexOf(x.subscriptionId) === -1) {
        sList.push(x.subscriptionId);
      }
      if (rgList.indexOf(x.resourceGroup) === -1) {
        rgList.push(x.resourceGroup);
      }
      if (mcList.indexOf(x.meterCategory) === -1) {
        mcList.push(x.meterCategory);
      }
      if (scList.indexOf(x.meterSubCategory) === -1) {
        scList.push(x.meterSubCategory);
      }
    });
    setSubscriptionList(sList);
    setRsGroupList(rgList);
    setMainCategoryList(mcList);
    setSubCategoryList(scList);
    setAllResources(props.allResources || []);
  };

  const closeModal = (refresh?: boolean) => {
    setOpen(false);
    props.closeModal(refresh);
  };

  const onStepSelect = (step: any) => {
    if (step?.id === 2) {
      if (selectedResource?.length === 0) {
        return;
      }
    }
    if (step?.id === 3) {
      if (!isPageOneValid || !isPageTwoValid) {
        return;
      }
    }
    setSelectedStep(step);
    steps.forEach((x) => {
      if (x.id === step.id) {
        x.status = commonService.stepStatus.CURRENT;
      } else if (x.id < step.id) {
        x.status = commonService.stepStatus.COMPLETE;
      } else {
        x.status = commonService.stepStatus.UPCOMING;
      }
    });
  };

  const onChangeSubscription = (value: string) => {
    setSubscription(value);
    setRsGroup(all);
    setMainCategory(all);
    setSubCategory(all);
    const rgList = [all];
    const mcList = [all];
    const scList = [all];
    (props.allResources || []).forEach((x) => {
      if (x.subscriptionId === value || value === all) {
        if (rgList.indexOf(x.resourceGroup) === -1) {
          rgList.push(x.resourceGroup);
        }
        if (mcList.indexOf(x.meterCategory) === -1) {
          mcList.push(x.meterCategory);
        }
        if (scList.indexOf(x.meterSubCategory) === -1) {
          scList.push(x.meterSubCategory);
        }
      }
    });
    setRsGroupList(rgList);
    setMainCategoryList(mcList);
    setSubCategoryList(scList);
    setSelectedResource([]);
  };

  const onChangeRsGroup = (value: string) => {
    setRsGroup(value);
    setMainCategory(all);
    setSubCategory(all);
    const mcList = [all];
    const scList = [all];
    (props.allResources || []).forEach((x) => {
      if (
        (x.subscriptionId === subscription || subscription === all) &&
        (x.resourceGroup === value || value === all)
      ) {
        if (mcList.indexOf(x.meterCategory) === -1) {
          mcList.push(x.meterCategory);
        }
        if (scList.indexOf(x.meterSubCategory) === -1) {
          scList.push(x.meterSubCategory);
        }
      }
    });
    setMainCategoryList(mcList);
    setSubCategoryList(scList);
    setSelectedResource([]);
  };

  const onChangeMainCategory = (value: string) => {
    setMainCategory(value);
    setSubCategory(all);
    const scList = [all];
    (props.allResources || []).forEach((x) => {
      if (
        (x.subscriptionId === subscription || subscription === all) &&
        (x.resourceGroup === rsGroup || rsGroup === all) &&
        (x.meterCategory === value || value === all)
      ) {
        if (scList.indexOf(x.meterSubCategory) === -1) {
          scList.push(x.meterSubCategory);
        }
      }
    });
    setSubCategoryList(scList);
    setSelectedResource([]);
  };

  const onChangeSubCategory = (value: string) => {
    setSubCategory(value);
    setSelectedResource([]);
  };

  const setFilter = () => {
    setTimeout(() => {
      const filterData: MonthCostCategoryResult[] = (
        props.allResources || []
      ).filter((x) => {
        return (
          (x.subscriptionId === subscription || subscription === all) &&
          (x.resourceGroup === rsGroup || rsGroup === all) &&
          (x.meterCategory === mainCategory || mainCategory === all) &&
          (x.meterSubCategory === subCategory || subCategory === all)
        );
      });
      setAllResources(filterData);
    }, 1);
  };

  const goBack = () => {
    onStepSelect(steps[selectedStep.id - 2]);
  };

  const onNext = () => {
    if (selectedStep?.id === 1) {
      if (!isPageOneValid) {
        return;
      } else {
        onStepSelect(steps[selectedStep.id]);
      }
    } else if (selectedStep?.id === 2) {
      if (!isPageTwoValid) {
        return;
      } else {
        onStepSelect(steps[selectedStep.id]);
      }
    }
  };

  const toggleAll = () => {
    setSelectedResource(checked || indeterminate ? [] : allResources);
    setChecked(!checked && !indeterminate);
    setIndeterminate(false);
  };

  const addResource = () => {
    if (isPageOneValid && isPageTwoValid && !loading) {
      const resourceIds = (selectedResource || []).map((x) => x.resourceId);
      const body: CreateOrUpdateOpportunityPlanResourceRequest = {
        opportunityPlanStepId: props?.planStepId || "",
        cspResourceIds: resourceIds,
        isActive: true,
      };
      setLoading(true);
      OpportunityService.createOpportunityPlanResource(body)
        .then((response: any) => {
          const requestList: CreateOrUpdateOpportunityPlanResourceTaskRequest[] = [];
          const result: OpportunityPlanResourceItem[] = response?.data?.result || [];
          (result || []).forEach((r: any) => {
            (selectedTemplates || []).forEach((detail: any, i: number) => {
              const request: CreateOrUpdateOpportunityPlanResourceTaskRequest = {
                opportunityPlanResourceId: r.id,
                opportunityPlanResourceTaskTemplateId: detail?.id,
                hours: detail?.hours,
                value: detail?.value,
                sequenceOrder: i,
                shouldBeCompletedInSequence: false,
                isActive: true,
                note: detail?.note,
                commandParameters: detail?.commandParameters
              };
              requestList.push(request);
            });
          });
          addResourceTask(requestList);
        })
        .catch((error) => {
          toast.error(error.message);
          setLoading(false);
        });
    }
  };

  const addResourceTask = (
    request: CreateOrUpdateOpportunityPlanResourceTaskRequest[]
  ) => {
    OpportunityService.createOpportunityPlanResourceTask(request)
      .then((response: any) => {
        setLoading(false);
        commonService.showMessage(response?.data || {});
        if (response?.data?.isSuccess) {
          closeModal(true);
        }
      })
      .catch((e: any) => {
        toast.error(`${e?.response?.data?.message}`);
        setLoading(false);
      });
  };

  const [showResourceDetailModal, setShowResourceDetailModal] =
    useState<boolean>(false);
  const [selectedResourceForDetail, setSelectedResourceForDetail] = useState<
    MonthCostCategoryResult | any
  >();
  const closeResourceDetailModal = () => {
    setShowResourceDetailModal(false);
  };

  const openResourceDetailModalModal = (
    e: any,
    resource: MonthCostCategoryResult
  ) => {
    e?.preventDefault();
    setSelectedResourceForDetail(JSON.parse(JSON.stringify(resource || {})));
    setShowResourceDetailModal(true);
  };

  const handleCommandParameterChange = (
    templateId: string,
    parameterName: string,
    value: string
  ) => {
    const updatedTemplates = selectedTemplates.map((template: OpportunityPlanResourceTaskTemplateItem) => {
      if (template.id === templateId) {
        const updatedParameters = template.commandParameters.map((param: CommandParameterItem) => {
          if (param.name === parameterName) {
            return { ...param, value: value };
          }
          return param;
        });
  
        return { ...template, commandParameters: updatedParameters };
      }
      return template;
    });
    onTemplatesChange(updatedTemplates);
  };
  
  const onTemplateAttributeChange = (attribute: string, newValue: any, id: string) => {
    const updatedTemplates = selectedTemplates.map((template) => {
        if (template.id === id) {
            // Create a new template object with the updated attribute (value or note)
            return { ...template, [attribute]: newValue }; // Dynamically update the specified attribute
        }
        return template;
    });

    onTemplatesChange(updatedTemplates); // This triggers the state update
  };


  return (
    <>
      <ResourceDetialModal
        show={showResourceDetailModal}
        closeModal={closeResourceDetailModal}
        resource={selectedResourceForDetail}
        date={props.date ?? ""}
        isCreate={true}
      />
      <ModalWrapper
        open={open}
        cancelButtonRef={cancelButtonRef}
        closeModal={closeModal}
      >
        <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full md:max-w-2xl lg:max-w-5xl sm:p-6">
          <div>
            <div className="mt-3 sm:mt-5">
              <Dialog.Title
                as="h3"
                className="text-base text-center font-semibold leading-6 text-gray-900"
              >
                {modalTitle}
              </Dialog.Title>
              <div className="mt-2">
              <StepView steps={steps} onStepSelect={onStepSelect} />

                <div className="space-y-6 mt-8 px-4">
                  {selectedStep?.id === 1 && (
                    <div>
                      <form className="space-y-6">
                        <div className="mt-6 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                          <div className="sm:col-span-3">
                            <label
                              htmlFor="subscription"
                              className="block text-sm font-medium leading-6 text-gray-900"
                            >
                              Subscription
                            </label>
                            <div className="mt-1 mb-1">
                              <select
                                id="subscription"
                                name="subscription"
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                value={subscription}
                                required
                                onChange={(e) =>
                                  onChangeSubscription(e?.target?.value)
                                }
                              >
                                {subscriptionList.map((sub: string) => (
                                  <option value={sub} key={sub}>
                                    {sub}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                          <div className="sm:col-span-3">
                            <label
                              htmlFor="rsGroup"
                              className="block text-sm font-medium leading-6 text-gray-900"
                            >
                              Resource Group
                            </label>
                            <div className="mt-1 mb-1">
                              <select
                                id="rsGroup"
                                name="rsGroup"
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                value={rsGroup}
                                required
                                onChange={(e) =>
                                  onChangeRsGroup(e?.target?.value)
                                }
                              >
                                {rsGroupList.map((rs: string) => (
                                  <option value={rs} key={rs}>
                                    {rs}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                          <div className="sm:col-span-3">
                            <label
                              htmlFor="mainCategory"
                              className="block text-sm font-medium leading-6 text-gray-900"
                            >
                              Main Category
                            </label>
                            <div className="mt-1 mb-1">
                              <select
                                id="mainCategory"
                                name="mainCategory"
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                value={mainCategory}
                                required
                                onChange={(e) =>
                                  onChangeMainCategory(e?.target?.value)
                                }
                              >
                                {mainCategoryList.map((mc: string) => (
                                  <option value={mc} key={mc}>
                                    {mc}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                          <div className="sm:col-span-3">
                            <label
                              htmlFor="subCategory"
                              className="block text-sm font-medium leading-6 text-gray-900"
                            >
                              Sub Category
                            </label>
                            <div className="mt-1 mb-1">
                              <select
                                id="subCategory"
                                name="subCategory"
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                value={subCategory}
                                required
                                onChange={(e) =>
                                  onChangeSubCategory(e?.target?.value)
                                }
                              >
                                {subCategoryList.map((sc: string) => (
                                  <option value={sc} key={sc}>
                                    {sc}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                        </div>
                      </form>

                      <div className="h-full overflow-y-auto max-h-[50vh]">
                        {allResources?.length > 0 ? (
                          <table className="min-w-full table-fixed divide-y divide-gray-300">
                            <thead>
                              <tr>
                                <th
                                  scope="col"
                                  className="relative px-7 sm:w-12 sm:px-6"
                                >
                                  <input
                                    type="checkbox"
                                    className="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                    ref={checkbox}
                                    checked={checked}
                                    onChange={toggleAll}
                                  />
                                </th>
                                <th
                                  scope="col"
                                  className="min-w-[12rem] py-3.5 pr-3 text-left text-sm font-semibold text-gray-900"
                                >
                                  Resource Name
                                </th>
                                <th
                                  scope="col"
                                  className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                                >
                                  Meter Name
                                </th>
                                <th
                                  scope="col"
                                  className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                                >
                                  Total Cost
                                </th>
                                <th
                                  scope="col"
                                  className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                                >
                                  Price
                                </th>
                                <th
                                  scope="col"
                                  className="relative py-3.5 pl-3 pr-4 sm:pr-3"
                                >
                                  <span className="sr-only">View</span>
                                </th>
                              </tr>
                            </thead>
                            <tbody className="divide-y divide-gray-200 bg-white">
                              {allResources.map((resource, i: number) => (
                                <tr
                                  key={`${resource.resourceId}-filter-${i}`}
                                  className={
                                    selectedResource.includes(resource)
                                      ? "bg-gray-50"
                                      : undefined
                                  }
                                >
                                  <td className="relative px-7 sm:w-12 sm:px-6">
                                    {selectedResource.includes(resource) && (
                                      <div className="absolute inset-y-0 left-0 w-0.5 bg-indigo-600" />
                                    )}
                                    <input
                                      type="checkbox"
                                      className="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                      value={resource.resourceId}
                                      checked={selectedResource.includes(
                                        resource
                                      )}
                                      onChange={(e) =>
                                        setSelectedResource(
                                          e.target.checked
                                            ? [...selectedResource, resource]
                                            : selectedResource.filter(
                                                (p) => p !== resource
                                              )
                                        )
                                      }
                                    />
                                  </td>
                                  <td
                                    className={commonService.classNames(
                                      "whitespace-nowrap py-4 pr-3 text-sm font-medium",
                                      selectedResource.includes(resource)
                                        ? "text-indigo-600"
                                        : "text-gray-900"
                                    )}
                                  >
                                    {resource.name}
                                  </td>
                                  <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                    {resource.meterName}
                                  </td>
                                  <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                    ${resource.totalCost}
                                  </td>
                                  <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                    ${resource.maxPrice}
                                  </td>
                                  <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                    <ChevronRightIcon
                                      className="h-5 w-5 flex-none text-gray-400 cursor-pointer"
                                      aria-hidden="true"
                                      onClick={(e) =>
                                        openResourceDetailModalModal(
                                          e,
                                          resource
                                        )
                                      }
                                    />
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        ) : (
                          <button
                            type="button"
                            className="relative block w-full rounded-lg border-2 border-dashed border-gray-300 p-12 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                            onClick={() => initFilters()}
                          >
                            <svg
                              className="mx-auto h-12 w-12 text-gray-400"
                              stroke="currentColor"
                              fill="none"
                              viewBox="0 0 48 48"
                              aria-hidden="true"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth={2}
                                d="M8 14v20c0 4.418 7.163 8 16 8 1.381 0 2.721-.087 4-.252M8 14c0 4.418 7.163 8 16 8s16-3.582 16-8M8 14c0-4.418 7.163-8 16-8s16 3.582 16 8m0 0v14m0-4c0 4.418-7.163 8-16 8S8 28.418 8 24m32 10v6m0 0v6m0-6h6m-6 0h-6"
                              />
                            </svg>
                            <span className="mt-2 block text-sm font-semibold text-gray-900">
                              Reset Filters
                            </span>
                          </button>
                        )}
                      </div>
                    </div>
                  )}
                  {selectedStep?.id === 2 && (
                    <div id="resourceModal-template-sec" style={{ minHeight: '25vh' }}>
                      <div className="mt-2">
                        <div>
                          <form className="space-y-6">
                            <div className="mt-6 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                              <div className="sm:col-span-6">
                                <label
                                  htmlFor="resourceModalTemplate"
                                  className="block text-sm font-medium leading-6 text-gray-900"
                                >
                                  Choose Template
                                </label>
                                <div className="relative w-full">
                                  <Multiselect
                                    displayValue="name"
                                    groupBy="cspType"
                                    onRemove={handleTemplatesRemove}
                                    onSearch={function noRefCheck(){}}
                                    onSelect={handleTemplatesChoice}
                                    options={props.templates}
                                    selectedValues={selectedTemplates}
                                  />
                                </div>
                                {selectedTemplates.length == 0 && (
                                  <p
                                    className="mt-2 text-sm text-red-600"
                                    id="order-error"
                                  >
                                    Template is required.
                                  </p>
                                )}
                              </div>

                              {selectedTemplates.map((templateDetail: OpportunityPlanResourceTaskTemplateItem) => (
                                templateDetail?.id && (
                                  <div key={templateDetail.id} className="sm:col-span-6">
                                  <div className="border-t border-gray-900 mt-6 mb-3"></div>
                                  <div className="mb-4" id={`resourceModalName-${templateDetail.id}`}>
                                    <div className="flex">
                                      <dt className="text-sm font-medium leading-6 text-gray-900 mr-4 w-1/4">
                                        Template Name:
                                      </dt>
                                      <dd className="text-sm leading-6 text-gray-700 flex-grow">
                                        {templateDetail?.name}
                                      </dd>
                                    </div>
                                  </div>
                                  <div className="mb-4" id={`resourceModalDesc-${templateDetail.id}`}>
                                    <div className="flex">
                                      <dt className="text-sm font-medium leading-6 text-gray-900 mr-4 w-1/4">
                                        Description:
                                      </dt>
                                      <dd className="text-sm leading-6 text-gray-700 flex-grow">
                                        {templateDetail?.description}
                                      </dd>
                                    </div>
                                  </div>
                                  <div className="mb-4" id={`resourceModalValue-${templateDetail.id}`}>
                                    <div className="flex">
                                      <dt className="text-sm font-medium leading-6 text-gray-900 mr-4 w-1/4">
                                        Value<span className="text-red-500">*</span>:
                                      </dt>
                                      <div className="mt-2">
                                        <input
                                          id={`resourceModalValueInput-${templateDetail.id}`}
                                          name="resourceModalValueInput"
                                          type="number"
                                          required
                                          value={templateDetail?.value}
                                          onChange={(e) =>
                                            onTemplateAttributeChange("value", +e?.target?.value as number, templateDetail.id)
                                          }
                                          className={commonService.classNames(
                                            "block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6",
                                            !templateDetail?.value
                                              ? "text-red-900 ring-red-300 placeholder:text-red-300 focus:ring-red-500"
                                              : ""
                                          )}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="mb-4" id={`resourceModalDesc-${templateDetail.id}`}>
                                    <div className="flex">
                                      <dt className="text-sm font-medium leading-6 text-gray-900 mr-4 w-1/4">
                                        Note:
                                      </dt>
                                      <div className="mt-2">
                                        <input
                                          id={`resourceModalNoteInput-${templateDetail.id}`}
                                          name="resourceModalNoteInput"
                                          type="text"
                                          required
                                          value={templateDetail?.note}
                                          onChange={(e) =>
                                            onTemplateAttributeChange("note", e?.target?.value as string, templateDetail.id)
                                          }
                                          className={commonService.classNames(
                                            "block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                          )}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="mb-4" id={`resourceModalCommand-${templateDetail.id}`}>
                                    <div className="flex">
                                      <dt className="text-sm font-medium leading-6 text-gray-900 mr-4 w-1/4">
                                        Base Command:
                                      </dt>
                                      <dd className="text-sm leading-6 text-gray-700 flex-grow">
                                        {templateDetail?.command && (
                                          <dd className="mt-1 text-sm leading-6 text-gray-100 bg-gray-900 sm:col-span-2 sm:mt-0 rounded-lg px-3 py-1">
                                            {templateDetail?.command}
                                          </dd>
                                        )}
                                      </dd>
                                    </div>
                                  </div>

                                  {templateDetail?.commandParameters.length > 0 && 
                                    templateDetail?.commandParameters.some(x => x.isAutoFilled === false) && 
                                    <div className="mb-4" id={`resourceModalParams-${templateDetail.id}`}>
                                      <div className="sm:gap-4 sm:px-0">
                                        <dt className="text-sm font-medium leading-6 text-gray-900">
                                          Command Parameters:
                                        </dt>
                                      </div>
                                    </div>
                                  }
                                  
                                  {templateDetail?.commandParameters.map(
                                    (item: CommandParameterItem) =>
                                      item.isAutoFilled ? null : (
                                        <div key={item.name} className="mb-4" id={`resourceModalParam-${item.name}`}>
                                          <div className="flex">
                                            <dt className="text-sm font-medium leading-6 text-gray-900 mr-4 w-1/4">
                                              {item.name}
                                              {item.isRequired && <span className="text-red-500">*</span>}:
                                            </dt>
                                            <dd className="text-sm leading-6 text-gray-700 flex-grow">
                                              <input
                                                type="text"
                                                value={item.value}
                                                onChange={(e) =>
                                                  handleCommandParameterChange(
                                                    templateDetail.id,
                                                    item.name,
                                                    e.target.value
                                                  )
                                                }
                                                className={commonService.classNames(
                                                  "block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                )}
                                                required={item.isRequired}
                                              />
                                            </dd>
                                          </div>
                                        </div>
                                      )
                                  )}
                                </div>
                                )
                              ))}
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  )}
                  {selectedStep?.id === 3 && (
                    <div>
                      <div className="mt-2">
                        <div>
                          <div>
                            <div className="px-4 sm:px-0">
                              <h3 className="text-base font-semibold leading-7 text-gray-900">
                                Selected Resources:
                              </h3>
                            </div>

                            <table className="min-w-full table-fixed divide-y divide-gray-300">
                              <thead>
                                <tr>
                                  <th
                                    scope="col"
                                    className="min-w-[12rem] py-3.5 pr-3 text-left text-sm font-semibold text-gray-900"
                                  >
                                    Resource Name
                                  </th>
                                  <th
                                    scope="col"
                                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                                  >
                                    Meter Name
                                  </th>
                                  <th
                                    scope="col"
                                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                                  >
                                    Total Cost
                                  </th>
                                  <th
                                    scope="col"
                                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                                  >
                                    Price
                                  </th>
                                  <th
                                    scope="col"
                                    className="relative py-3.5 pl-3 pr-4 sm:pr-3"
                                  >
                                    <span className="sr-only">Edit</span>
                                  </th>
                                </tr>
                              </thead>
                              <tbody className="divide-y divide-gray-200 bg-white">
                                {selectedResource.map((resource, i: number) => (
                                  <tr
                                    key={`${resource.resourceId}-confirmation-${i}`}
                                    className={
                                      selectedResource.includes(resource)
                                        ? "bg-gray-50"
                                        : undefined
                                    }
                                  >
                                    <td className="whitespace-nowrap py-4 pr-3 text-sm font-medium text-indigo-600">
                                      {resource.name}
                                    </td>
                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                      {resource.meterName}
                                    </td>
                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                      ${resource.totalCost}
                                    </td>
                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                      ${resource.maxPrice}
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>

                            <div className="px-6 sm:px-0 mt-4">
                              <h3 className="text-base font-semibold leading-7 text-gray-900">
                                Selected Template:
                              </h3>
                            </div>

                            <table className="min-w-full table-fixed divide-y divide-gray-300">
                              <thead>
                                <tr>
                                  <th
                                    scope="col"
                                    className="min-w-[12rem] py-3.5 pr-3 text-left text-sm font-semibold text-gray-900"
                                  >
                                    Template Name
                                  </th>
                                  <th
                                    scope="col"
                                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                                  >
                                    Description
                                  </th>
                                  <th
                                    scope="col"
                                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                                  >
                                    Hours
                                  </th>
                                  <th
                                    scope="col"
                                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                                  >
                                    Value
                                  </th>
                                  <th
                                    scope="col"
                                    className="relative py-3.5 pl-3 pr-4 sm:pr-3"
                                  >
                                    <span className="sr-only">Edit</span>
                                  </th>
                                </tr>
                              </thead>
                              <tbody className="divide-y divide-gray-200 bg-white">
                                {selectedTemplates.map((template, i: number) => (
                                  <tr
                                    key={`${template.id}-confirmation-${i}`}
                                    className={
                                      selectedTemplates.includes(template)
                                        ? "bg-gray-50"
                                        : undefined
                                    }
                                  >
                                    <td className="whitespace-nowrap py-4 pr-3 text-sm font-medium text-indigo-600">
                                      {template.name}
                                    </td>
                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                      {template.description}
                                    </td>
                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                      {template.hours}
                                    </td>
                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                      ${template.value}
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>

                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div
          id="resource-footer-sec"
            className={commonService.classNames(
              "mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:gap-3",
              selectedStep?.id === 1 ? "sm:grid-cols-2" : "sm:grid-cols-3"
            )}
          >
            {selectedStep?.id === 3 && (
              <button
                type="submit"
                disabled={loading || !isPageOneValid || !isPageTwoValid}
                className={commonService.classNames(
                  "inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:col-start-3",
                  loading || !isPageOneValid || !isPageTwoValid
                    ? "disabled:opacity-75"
                    : ""
                )}
                onClick={() => addResource()}
              >
                <Spinner show={loading} />
                Add
              </button>
            )}
            {selectedStep?.id < steps.length && (
              <button
                type="button"
                disabled={
                  (selectedStep?.id === 1 && !isPageOneValid) ||
                  (selectedStep?.id === 2 && !isPageTwoValid) ||
                  (selectedStep?.id === 2 && isNextButtonDisabled)
                }
                className={commonService.classNames(
                  "inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600",
                  selectedStep?.id === 1 ? "sm:col-start-2" : "sm:col-start-3",
                  (selectedStep?.id === 1 && !isPageOneValid) ||
                    (selectedStep?.id === 2 && !isPageTwoValid)
                    ? "disabled:opacity-75"
                    : ""
                )}
                onClick={() => onNext()}
              >
                Next
              </button>
            )}
            {selectedStep?.id > 1 && (
              <button
                type="button"
                className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-2 sm:mt-0"
                onClick={() => goBack()}
              >
                Back
              </button>
            )}
            <button
              type="button"
              className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
              onClick={() => closeModal()}
              ref={cancelButtonRef}
            >
              Cancel
            </button>
          </div>
        </Dialog.Panel>
      </ModalWrapper>
    </>
  );
}