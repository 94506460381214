import commonService from "../service/commonService";
import {
  ArrowUpIcon,
  ArrowDownIcon,
  CheckIcon,
  ChevronDownIcon,
  ChevronUpDownIcon,
  ChevronUpIcon,
} from "@heroicons/react/20/solid";
import { Popover, Transition } from "@headlessui/react";
import { Fragment, useEffect, useState } from "react";
import { ArrowTopRightOnSquareIcon } from "@heroicons/react/24/outline";
import { Tooltip } from "react-tooltip";

interface Props {
  navigation: any[];
  currentMenuSelection: number;
  setCurrentMenuSelection: (id: number) => void;
}

export function SecondaryNavigationHeader(props: Readonly<Props>) {
  const isCurrentSelection = (id: number) => {
    return id === props?.currentMenuSelection;
  };

  return (
    <header className="border-b border-white/5">
      {/* Secondary navigation */}
      <nav className="flex overflow-x-auto py-4">
        <ul className="flex min-w-full flex-none gap-x-6 px-4 text-sm font-semibold leading-6 text-gray-400 sm:px-6 lg:px-8">
          {(props?.navigation || []).map((item) => (
            <li key={item.name}>
              <a
                href={item.href}
                onClick={() => props?.setCurrentMenuSelection(item.id)}
                className={
                  isCurrentSelection(item.id)
                    ? "text-indigo-400"
                    : "text-gray-500"
                }
              >
                {item.name}
              </a>
            </li>
          ))}
        </ul>
      </nav>
    </header>
  );
}

interface TabsProps {
  tabs: Array<{ name: string; href: string; current: boolean }>;
  currentTabSelection: string;
  setCurrentTabSelection: (name: string) => void;
}

export function TabsView(props: Readonly<TabsProps>) {
  return (
    <div className="border-b border-gray-200">
      <nav className="-mb-px flex space-x-8" aria-label="Tabs">
        {(props?.tabs || []).map((tab) => (
          <a
            key={tab.name}
            href={tab.href}
            onClick={() => props?.setCurrentTabSelection(tab.name)}
            className={commonService.classNames(
              tab.name === props?.currentTabSelection
                ? "border-indigo-500 text-indigo-600"
                : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
              "whitespace-nowrap border-b-2 py-4 px-1 text-sm font-medium"
            )}
            aria-current={tab.current ? "page" : undefined}
          >
            {tab.name}
          </a>
        ))}
      </nav>
    </div>
  );
}

export const ChartTooltip = ({ active, payload, label }: any) => {
  if (active && payload?.length && payload[0]["payload"]) {
    return (
      <div
        key={payload.date}
        className="relative flex items-center space-x-3 rounded-lg border border-gray-300 bg-white px-6 py-5 shadow-sm focus-wi)thin:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 hover:border-gray-400"
      >
        <div className="min-w-0 flex-1">
          <a href="#" className="focus:outline-none">
            <span className="absolute inset-0" aria-hidden="true" />
            <p className="text-md text-gray-900">Date : {label}</p>
            {Object.keys(payload[0]["payload"]).map((payloadItem) => {
              const value = payload[0].payload[payloadItem];

              if (typeof value === "number" && value !== 0) {
                return (
                  <p
                    className="truncate text-sm text-gray-500"
                    key={payloadItem}
                  >
                    {payloadItem} : {commonService.formatToUSCurrency(value)}
                  </p>
                );
              } else {
                return null;
              }
            })}
          </a>
        </div>
      </div>
    );
  }

  return null;
};

type PercentageChangeProps = {
  prevValue: number;
  currentValue: number;
};

type StatWithPercentageChange = {
  stat: string | number;
  prevValue: number;
  currentValue: number;
};

export const StatWithPercentageChange = (props: StatWithPercentageChange) => {
  return (
    <div className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 flex items-baseline">
      <p className="font-semibold text-gray-900 flex"> {props.stat}</p>
      <PercentageChange
        prevValue={props.prevValue}
        currentValue={props.currentValue}
      />
    </div>
  );
};

export const PercentageChange = (props: PercentageChangeProps) => {
  const changeDirection = commonService.isNegativeChange(
    props.prevValue,
    props.currentValue
  );
  if (changeDirection === null) return null;

  const percentageChange = commonService.getPercentageChange(
    props.prevValue,
    props.currentValue
  );
  if (percentageChange === null || percentageChange === "0%") return null;

  return (
    <p
      className={commonService.classNames(
        !changeDirection ? "text-red-600" : "text-green-600",
        "ml-2 flex items-baseline text-sm font-semibold"
      )}
    >
      {!changeDirection ? (
        <ArrowUpIcon
          className="h-5 w-5 flex-shrink-0 self-center text-red-500"
          aria-hidden="true"
        />
      ) : (
        <ArrowDownIcon
          className="h-5 w-5 flex-shrink-0 self-center text-green-500"
          aria-hidden="true"
        />
      )}
      <a
        href="#"
        className="transititext-primary text-primary transition duration-150 ease-in-out hover:text-primary-600 focus:text-primary-600 active:text-primary-700 dark:text-primary-400 dark:hover:text-primary-500 dark:focus:text-primary-500 dark:active:text-primary-600"
        data-te-toggle="tooltip"
        title={"From: " + props.prevValue}
      >
        {percentageChange}
      </a>
    </p>
  );
};

interface StepProps {
  steps: any[];
  onStepSelect: (step: any) => void;
}

export const StepView = (props: StepProps) => {
  return (
    <nav aria-label="Progress">
      <ol className="divide-y divide-gray-300 rounded-md border border-gray-300 md:flex md:divide-y-0">
        {(props?.steps || []).map((step, stepIdx) => (
          <li key={step.name} className="relative md:flex md:flex-1">
            {step.status === commonService.stepStatus.COMPLETE && (
              <a
                href={step.href}
                className="group flex w-full items-center"
                onClick={() => props?.onStepSelect(step)}
              >
                <span className="flex items-center px-6 py-4 text-sm font-medium">
                  <span className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full bg-indigo-600 group-hover:bg-indigo-800">
                    <CheckIcon
                      className="h-6 w-6 text-white"
                      aria-hidden="true"
                    />
                  </span>
                  <span className="ml-4 text-sm font-medium text-gray-900">
                    {step.name}
                  </span>
                </span>
              </a>
            )}
            {step.status === commonService.stepStatus.CURRENT && (
              <a
                href={step.href}
                className="flex items-center px-6 py-4 text-sm font-medium"
                aria-current="step"
                onClick={() => props?.onStepSelect(step)}
              >
                <span className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full border-2 border-indigo-600">
                  <span className="text-indigo-600">{step.id}</span>
                </span>
                <span className="ml-4 text-sm font-medium text-indigo-600">
                  {step.name}
                </span>
              </a>
            )}
            {step.status === commonService.stepStatus.UPCOMING && (
              <a
                href={step.href}
                className="group flex items-center"
                onClick={() => props?.onStepSelect(step)}
              >
                <span className="flex items-center px-6 py-4 text-sm font-medium">
                  <span className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full border-2 border-gray-300 group-hover:border-gray-400">
                    <span className="text-gray-500 group-hover:text-gray-900">
                      {step.id}
                    </span>
                  </span>
                  <span className="ml-4 text-sm font-medium text-gray-500 group-hover:text-gray-900">
                    {step.name}
                  </span>
                </span>
              </a>
            )}

            {stepIdx !== (props?.steps || []).length - 1 ? (
              <div
                className="absolute right-0 top-0 hidden h-full w-5 md:block"
                aria-hidden="true"
              >
                <svg
                  className="h-full w-full text-gray-300"
                  viewBox="0 0 22 80"
                  fill="none"
                  preserveAspectRatio="none"
                >
                  <path
                    d="M0 -2L20 40L0 82"
                    vectorEffect="non-scaling-stroke"
                    stroke="currentcolor"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
            ) : null}
          </li>
        ))}
      </ol>
    </nav>
  );
};

interface StepResourceProps {
  data: any[];
}

export const StepResourceTable = (props: StepResourceProps) => {
  return (
    <table className="min-w-full table-fixed divide-y divide-gray-300">
      <thead>
        <tr>
          <th
            scope="col"
            className="min-w-[12rem] py-3.5 pr-3 text-left text-sm font-semibold text-gray-900"
          >
            Resource Name
          </th>
          <th
            scope="col"
            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
          >
            New Resource
          </th>
          <th
            scope="col"
            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
          >
            Task
          </th>
        </tr>
      </thead>
      <tbody className="divide-y divide-gray-200 bg-white">
        {(props?.data || []).map((resource) => (
          <tr
            key={`${resource.id}`}
            className={resource.isNew ? "bg-gray-50" : undefined}
          >
            <td className="whitespace-nowrap py-4 pr-3 text-sm font-medium text-gray-500">
              {resource?.resourceId?.split("/")[8]}
            </td>
            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
              {resource.isNew ? "Yes" : "No"}
            </td>
            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
              {!resource.isNew &&
                (resource?.resourceData?.opportunityPlanResourceTask || []).map(
                  (task: any) => (
                    <div
                      key={`${resource.id}${task.id}`}
                      className="relative flex items-start"
                    >
                      {task?.opportunityPlanResourceTaskTemplate?.name}
                    </div>
                  )
                )}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

interface SortTableHeaderProps {
  current: boolean;
  asc: boolean;
  sortList: NameValuePair[];
  sortBy: string;
  sortClick: (value: string) => void;
}

export const SortTableHeader = (props: SortTableHeaderProps) => {
  const isSorted = (name: string) => {
    return name === props?.sortBy;
  };

  return (
    <Popover className="relative mt-2">
      <Popover.Button className="inline-flex items-center gap-x-1 text-sm font-semibold leading-6 text-gray-900">
        {props?.current ? (
          <>
            {props?.asc ? (
              <ChevronDownIcon className="h-4 w-4" aria-hidden="true" />
            ) : (
              <ChevronUpIcon className="h-4 w-4" aria-hidden="true" />
            )}
          </>
        ) : (
          <ChevronUpDownIcon className="h-5 w-5" aria-hidden="true" />
        )}
      </Popover.Button>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-200"
        enterFrom="opacity-0 translate-y-1"
        enterTo="opacity-100 translate-y-0"
        leave="transition ease-in duration-150"
        leaveFrom="opacity-100 translate-y-0"
        leaveTo="opacity-0 translate-y-1"
      >
        <Popover.Panel
          className="absolute z-10 mt-5 flex w-60 max-w-max -translate-x-1/2 px-4"
          style={{ left: "3rem" }}
        >
          <div className="w-screen max-w-md flex-auto overflow-hidden rounded-3xl bg-white text-sm leading-6 shadow-lg ring-1 ring-gray-900/5">
            <div className="p-4">
              {(props?.sortList || []).map((name: NameValuePair) => (
                <div className="flex items-center" key={name.name}>
                  {name.name}
                  <a href="#" onClick={() => props?.sortClick(name.value)}>
                    {isSorted(name.value) ? (
                      <>
                        {props?.asc ? (
                          <ChevronDownIcon
                            className="h-4 w-4"
                            aria-hidden="true"
                          />
                        ) : (
                          <ChevronUpIcon
                            className="h-4 w-4"
                            aria-hidden="true"
                          />
                        )}
                      </>
                    ) : (
                      <ChevronUpDownIcon
                        className="h-5 w-5"
                        aria-hidden="true"
                      />
                    )}
                  </a>
                </div>
              ))}
            </div>
          </div>
        </Popover.Panel>
      </Transition>
    </Popover>
  );
};

interface ProjectFormProps {
  project: ProjectItem | any;
  onInputChange: (project: ProjectItem) => void;
  show?: boolean;
  onCheckError: (hasError: boolean) => void;
}

const projectErrorState: any = {
  name: { hasError: false, required: true },
  description: { hasError: false, required: true },
  maturity: { hasError: false, required: true },
  phase: { hasError: false, required: true },
};

export const ProjectForm = (props: ProjectFormProps) => {
  const [error, setError] = useState<any>(
    JSON.parse(JSON.stringify(projectErrorState))
  );

  useEffect(() => {
    checkError();
  }, [props.show]);

  useEffect(() => {
    checkError();
  }, [props?.project]);

  const onInputChange = (fieldName: string, value: string) => {
    const field = error[fieldName] || {};
    field.hasError = !value;
    const model = JSON.parse(JSON.stringify(props?.project));
    model[fieldName] = value;
    error[fieldName] = field;
    props?.onInputChange(model);
  };

  const checkError = () => {
    let isError = false;
    for (const key of Object.keys(projectErrorState) || []) {
      if (projectErrorState[key]?.required && !props?.project[key]) {
        isError = true;
      }
    }
    props?.onCheckError(isError);
  };
  return (
    <form className="space-y-6">
      <div>
        <label
          htmlFor="name"
          className="block text-sm font-medium leading-6 text-gray-900"
        >
          Name
        </label>
        <div className="mt-2">
          <input
            id="name"
            name="name"
            type="text"
            autoComplete="name"
            required
            value={props?.project?.name}
            onChange={(e) => onInputChange("name", e?.target?.value)}
            className={commonService.classNames(
              "block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6",
              error?.name?.hasError
                ? "text-red-900 ring-red-300 placeholder:text-red-300 focus:ring-red-500"
                : ""
            )}
          />
        </div>
        {error?.name?.hasError && (
          <p className="mt-2 text-sm text-red-600" id="order-error">
            Name is required.
          </p>
        )}
      </div>

      <div>
        <label
          htmlFor="description"
          className="block text-sm font-medium leading-6 text-gray-900"
        >
          Description
        </label>
        <div className="mt-2">
          <input
            id="description"
            name="description"
            type="text"
            required
            value={props?.project?.description}
            onChange={(e) => onInputChange("description", e?.target?.value)}
            className={commonService.classNames(
              "block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6",
              error?.description?.hasError
                ? "text-red-900 ring-red-300 placeholder:text-red-300 focus:ring-red-500"
                : ""
            )}
          />
        </div>
        {error?.description?.hasError && (
          <p className="mt-2 text-sm text-red-600" id="order-error">
            Description is required.
          </p>
        )}
      </div>

      <div>
        <label
          htmlFor="maturity"
          className="block text-sm font-medium leading-6 text-gray-900"
        >
          FinOps Maturity
        </label>
        <div className="mt-1 mb-1">
          <select
            id="maturity"
            name="maturity"
            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 [&_*]:text-black"
            value={props?.project?.maturity}
            required
            onChange={(e) => onInputChange("maturity", e?.target?.value)}
          >
            {commonService.maturityList.map((c: string) => (
              <option key={c}>{c}</option>
            ))}
          </select>
        </div>
      </div>

      <div>
        <label
          htmlFor="phase"
          className="block text-sm font-medium leading-6 text-gray-900"
        >
          FinOps Phase
        </label>
        <div className="mt-1 mb-1">
          <select
            id="phase"
            name="phase"
            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 [&_*]:text-black"
            value={props?.project?.phase}
            required
            onChange={(e) => onInputChange("phase", e?.target?.value)}
          >
            {commonService.phaseList.map((c: string) => (
              <option key={c}>{c}</option>
            ))}
          </select>
        </div>
      </div>
    </form>
  );
};

interface PlanFormProps {
  plan: OpportunityPlanItem | any;
  isEdit?: boolean;
  onInputChange: (plan: OpportunityPlanItem) => void;
  show?: boolean;
  onCheckError: (hasError: boolean) => void;
  project: ProjectItem;
  onSetSeq: (seq: string) => void;
  checkingError?: boolean;
  onCheckingError?: boolean;
}

const planErrorState: any = {
  name: { hasError: false, required: true },
  description: { hasError: false, required: true },
  planCode: { hasError: false, required: true, minErr: false },
  startOn: { hasError: false, required: true, minErr: false },
  dueOn: { hasError: false, required: true, minErr: false },
  status: { hasError: false, required: true },
};

export const PlanForm = (props: PlanFormProps) => {
  const [error, setError] = useState<any>(
    JSON.parse(JSON.stringify(projectErrorState))
  );

  useEffect(() => {
    checkError();
  }, [props.show]);

  useEffect(() => {
    checkError();
  }, [props?.plan]);

  useEffect(() => {
    initSeq();
  }, []);

  useEffect(() => {
    if (props?.checkingError) {
      checkError();
    }
  }, [props?.checkingError]);

  useEffect(() => {
    if (props?.onCheckingError) {
      onCheckError();
    }
  }, [props?.onCheckingError]);

  const initSeq = () => {
    if (!props?.isEdit && props?.project) {
      props?.onSetSeq(`00${props?.project?.opportunityPlan?.length + 1}`);
    }
  };

  const validateDates = (startOn: string, dueOn: string) => {
    const startDate = new Date(startOn);
    const dueDate = new Date(dueOn);
    return dueDate >= startDate;
  };

  const onCheckError = () => {
    const model = JSON.parse(JSON.stringify(props?.plan));
    for (const key of Object.keys(planErrorState) || []) {
      onInputChange(key, model[key] || "");
    }
  };

  const onInputChange = (fieldName: string, value: string) => {
    const field = error[fieldName] || {};
    field.hasError = !value;
    if (fieldName === "planCode") {
      if (value?.length < 3 || value?.length > 5) {
        field.minErr = true;
        field.hasError = true;
      } else {
        field.minErr = false;
        field.hasError = false;
      }
    }
    if (fieldName === "dueOn" || fieldName === "startOn") {
      const dueOn = fieldName === "dueOn" ? value: props?.plan?.dueOn;
      const startOn = fieldName === "startOn" ? value: props?.plan?.startOn;
      if (validateDates(startOn || "", dueOn || "")) {
        if(error?.dueOn) {
          error.dueOn.minErr = false;
          error.dueOn.hasError = false;
        }
        if(error?.startOn) {
          error.startOn.minErr = false;
          error.startOn.hasError = false;
        }
      } else {
        field.minErr = true;
        field.hasError = true;
      }
    }
    const model = JSON.parse(JSON.stringify(props?.plan));
    model[fieldName] = value;
    error[fieldName] = field;
    props?.onInputChange(model);
  };

  const checkError = () => {
    let isError = false;
    for (const key of Object.keys(planErrorState) || []) {
      if (
        (error[key]?.required && !props?.plan[key]) ||
        error[key]?.hasError ||
        (key === "planCode" &&
          (props?.plan[key]?.length < 3 || props?.plan[key]?.length > 5))
      ) {
        isError = true;
      }
    }
    props?.onCheckError(isError);
  };
  return (
    <form className="space-y-6">
      <div className="mt-6 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
        <div className="sm:col-span-3">
          <label
            htmlFor="name"
            className="block text-sm font-medium leading-6 text-gray-900"
          >
            Name
          </label>
          <div className="mt-2">
            <input
              id="name"
              name="name"
              type="text"
              autoComplete="name"
              required
              value={props?.plan?.name}
              onChange={(e) => onInputChange("name", e?.target?.value)}
              className={commonService.classNames(
                "block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6",
                error?.name?.hasError
                  ? "text-red-900 ring-red-300 placeholder:text-red-300 focus:ring-red-500"
                  : ""
              )}
            />
          </div>
          {error?.name?.hasError && (
            <p className="mt-2 text-sm text-red-600" id="order-error">
              Name is required.
            </p>
          )}
        </div>

        {props?.isEdit && (
          <div className="sm:col-span-3">
            <label
              htmlFor="planCode"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Plan Code
            </label>
            <div className="mt-2">
              <input
                id="planCode"
                name="planCode"
                type="text"
                required
                value={props?.plan?.planCode}
                onChange={(e) => onInputChange("planCode", e?.target?.value)}
                className={commonService.classNames(
                  "block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6",
                  error?.planCode?.hasError
                    ? "text-red-900 ring-red-300 placeholder:text-red-300 focus:ring-red-500"
                    : ""
                )}
              />
            </div>
            {error?.planCode?.hasError && (
              <p className="mt-2 text-sm text-red-600" id="order-error">
                {error?.planCode?.minErr
                  ? "Plan Code should be 3-5 characters."
                  : "Plan Code is required."}
              </p>
            )}
          </div>
        )}

        {!props?.isEdit && (
          <div className="sm:col-span-3">
            <label
              htmlFor="planCode"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Plan Code
            </label>
            <div className="relative mt-2 rounded-md shadow-sm">
              <input
                id="planCode"
                name="planCode"
                type="text"
                required
                value={props?.plan?.planCode}
                onChange={(e) => onInputChange("planCode", e?.target?.value)}
                className={commonService.classNames(
                  "block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6",
                  error?.planCode?.hasError
                    ? "text-red-900 ring-red-300 placeholder:text-red-300 focus:ring-red-500"
                    : ""
                )}
              />
              <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                <span className="text-gray-500 sm:text-sm" id="price-currency">
                  {/* {seq} */}
                </span>
              </div>
            </div>
            {error?.planCode?.hasError && (
              <p className="mt-2 text-sm text-red-600" id="order-error">
                {error?.planCode?.minErr
                  ? "Plan Code should be 3-5 characters."
                  : "Plan Code is required."}
              </p>
            )}
          </div>
        )}

        <div className="sm:col-span-3">
          <label
            htmlFor="status"
            className="block text-sm font-medium leading-6 text-gray-900"
          >
            Status
          </label>
          <div className="mt-1 mb-1">
            <select
              id="status"
              name="status"
              className={commonService.classNames(
                "block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6",
                error?.status?.hasError
                  ? "text-red-900 ring-red-300 placeholder:text-red-300 focus:ring-red-500"
                  : ""
              )}
              value={props?.plan?.status}
              required
              disabled={!props?.isEdit}
              onChange={(e) => onInputChange("status", e?.target?.value)}
            >
              {commonService.planStatusList.map((status: string) => (
                <option value={status} key={status}>
                  {status}
                </option>
              ))}
            </select>
          </div>
          {error?.status?.hasError && (
            <p className="mt-2 text-sm text-red-600" id="order-error">
              Status is required.
            </p>
          )}
        </div>

        <div className="sm:col-span-6">
          <label
            htmlFor="description"
            className="block text-sm font-medium leading-6 text-gray-900"
          >
            Description
          </label>
          <div className="mt-2">
            <textarea
              rows={4}
              name="description"
              id="description"
              className={commonService.classNames(
                "block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6",
                error?.description?.hasError
                  ? "text-red-900 ring-red-300 placeholder:text-red-300 focus:ring-red-500"
                  : ""
              )}
              required
              value={props?.plan?.description}
              onChange={(e) => onInputChange("description", e?.target?.value)}
            />
          </div>
          {error?.description?.hasError && (
            <p className="mt-2 text-sm text-red-600" id="order-error">
              Description is required.
            </p>
          )}
        </div>

        <div className="sm:col-span-3">
          <label
            htmlFor="startOn"
            className="block text-sm font-medium leading-6 text-gray-900"
          >
            Start On
          </label>
          <div className="mt-2">
            <input
              id="startOn"
              name="startOn"
              type="date"
              required
              value={props?.plan?.startOn}
              onChange={(e) => onInputChange("startOn", e?.target?.value)}
              className={commonService.classNames(
                "block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6",
                error?.startOn?.hasError
                  ? "text-red-900 ring-red-300 placeholder:text-red-300 focus:ring-red-500"
                  : ""
              )}
            />
          </div>
          {error?.startOn?.hasError && (
            <p className="mt-2 text-sm text-red-600" id="startOn-error">
              {error?.startOn?.minErr
                ? "Start Date should not be later than Due Date."
                : "Start On is required."}
            </p>
          )}
        </div>

        <div className="sm:col-span-3">
          <label
            htmlFor="dueOn"
            className="block text-sm font-medium leading-6 text-gray-900"
          >
            Due On
          </label>
          <div className="mt-2">
            <input
              id="dueOn"
              name="dueOn"
              type="date"
              required
              value={props?.plan?.dueOn}
              onChange={(e) => onInputChange("dueOn", e?.target?.value)}
              className={commonService.classNames(
                "block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6",
                error?.dueOn?.hasError
                  ? "text-red-900 ring-red-300 placeholder:text-red-300 focus:ring-red-500"
                  : ""
              )}
            />
          </div>
          {error?.dueOn?.hasError && (
            <p className="mt-2 text-sm text-red-600" id="dueOn-error">
              {error?.dueOn?.minErr
                ? "Due Date should not be before Start Date."
                : "Due On is required."}
            </p>
          )}
        </div>
      </div>
    </form>
  );
};

interface StepFormProps {
  step: OpportunityPlanStepItem | any;
  onInputChange: (step: OpportunityPlanStepItem) => void;
  show?: boolean;
  onCheckError: (hasError: boolean) => void;
  checkingError?: boolean;
  onCheckingError?: boolean;
}

const stepErrorState: any = {
  name: { hasError: false, required: true },
  description: { hasError: false, required: true },
};

export const StepForm = (props: StepFormProps) => {
  const [error, setError] = useState<any>(
    JSON.parse(JSON.stringify(stepErrorState))
  );

  useEffect(() => {
    checkError();
  }, [props.show]);

  useEffect(() => {
    checkError();
  }, [props?.step]);

  useEffect(() => {
    if (props?.checkingError) {
      checkError();
    }
  }, [props?.checkingError]);

  useEffect(() => {
    if (props?.onCheckingError) {
      onCheckError();
    }
  }, [props?.onCheckingError]);

  const onCheckError = () => {
    const model = JSON.parse(JSON.stringify(props?.step));
    for (const key of Object.keys(stepErrorState) || []) {
      onInputChange(key, model[key] || "");
    }
  };

  const onInputChange = (fieldName: string, value: string) => {
    const field = error[fieldName] || {};
    field.hasError = !value;
    const model = JSON.parse(JSON.stringify(props?.step));
    model[fieldName] = value;
    error[fieldName] = field;
    props?.onInputChange(model);
  };

  const checkError = () => {
    let isError = false;
    for (const key of Object.keys(stepErrorState) || []) {
      if (stepErrorState[key]?.required && !props?.step[key]) {
        isError = true;
      }
    }
    props?.onCheckError(isError);
  };
  return (
    <form className="space-y-6">
      <div>
        <label
          htmlFor="name"
          className="block text-sm font-medium leading-6 text-gray-900"
        >
          Name
        </label>
        <div className="mt-2">
          <input
            id="name"
            name="name"
            type="text"
            autoComplete="name"
            required
            value={props?.step.name}
            onChange={(e) => onInputChange("name", e?.target?.value)}
            className={commonService.classNames(
              "block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6",
              error?.name?.hasError
                ? "text-red-900 ring-red-300 placeholder:text-red-300 focus:ring-red-500"
                : ""
            )}
          />
        </div>
        {error?.name?.hasError && (
          <p className="mt-2 text-sm text-red-600" id="order-error">
            Name is required.
          </p>
        )}
      </div>

      <div>
        <label
          htmlFor="description"
          className="block text-sm font-medium leading-6 text-gray-900"
        >
          Description
        </label>
        <div className="mt-2">
          <textarea
            rows={4}
            name="description"
            id="description"
            className={commonService.classNames(
              "block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6",
              error?.description?.hasError
                ? "text-red-900 ring-red-300 placeholder:text-red-300 focus:ring-red-500"
                : ""
            )}
            required
            value={props?.step.description}
            onChange={(e) => onInputChange("description", e?.target?.value)}
          />
        </div>
        {error?.description?.hasError && (
          <p className="mt-2 text-sm text-red-600" id="order-error">
            Description is required.
          </p>
        )}
      </div>
    </form>
  );
};


interface ResourceLinkProps {
  link?: string;
  index: number;
}

export function ResourceLink(props: Readonly<ResourceLinkProps>) {
  const openResourceLink = () => {
    window.open(props?.link, '_blank');
  }

  const handleKeyDown = (e?: any) => {
    if (e.key === "Enter") {
      window.open(props?.link, '_blank');
  }
  }

  return (
    <>
      {props?.link && (
        <a
          className="cursor-pointer"
          onClick={openResourceLink}
          onKeyDown={handleKeyDown}
          data-tooltip-id={"-vm-resource-link-tooltip" + props?.index}
        >
          <ArrowTopRightOnSquareIcon
            className="w-4 h-4 ml-1"
          />
          <Tooltip
            id={"-vm-resource-link-tooltip" + props?.index}
            arrowColor="transparent"
            place="top"
          >
            <div style={{}}>Open on Azure</div>
          </Tooltip>
        </a>
      )}
    </>
  );
}


interface EmptyViewProps {
  emptyText?: string;
}

export function EmptyView(props: Readonly<EmptyViewProps>) {
  return (
    <div>
      <button
        type="button"
        className="relative block w-full rounded-lg border-2 border-dashed border-gray-300 p-12 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
      >
        <svg
          className="mx-auto h-12 w-12 text-gray-400"
          stroke="currentColor"
          fill="none"
          viewBox="0 0 48 48"
          aria-hidden="true"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M8 14v20c0 4.418 7.163 8 16 8 1.381 0 2.721-.087 4-.252M8 14c0 4.418 7.163 8 16 8s16-3.582 16-8M8 14c0-4.418 7.163-8 16-8s16 3.582 16 8m0 0v14m0-4c0 4.418-7.163 8-16 8S8 28.418 8 24m32 10v6m0 0v6m0-6h6m-6 0h-6"
          />
        </svg>
        <span className="mt-2 block text-sm font-semibold text-gray-900">
          {props?.emptyText || 'No Data Found.'}
        </span>
      </button>
    </div>
  );
}