import { Dialog } from "@headlessui/react";
import { useEffect, useRef, useState } from "react";
import commonService from "../../../app/service/commonService";
import { ModalWrapper } from "../../common/modalWrapper";

interface Props {
  show: boolean;
  closeModal: () => void;
  filterData: CloudResourceFilter;
  refreshDetails: (filterData: CloudResourceFilter) => void;
  resourceData: any[];
  clearFilters: () => void;
}

export default function FilterModal(props: Readonly<Props>) {
  const [open, setOpen] = useState(props.show);
  const cancelButtonRef = useRef(null);

  const [filterData, setFilterData] = useState<CloudResourceFilter>(props?.filterData || {});

  const [locationList, setLocationList] = useState<string[]>([]);
  const [sizeList, setSizeList] = useState<string[]>([]);
  const [osList, setOsList] = useState<string[]>([]);

  const [hasError, setHasError] = useState<boolean>(false);
  const errorMsg = "Min value cannot be greater than the Max value.";

  const allText = "All";

  useEffect(() => {
    setOpen(props.show);
    if (props.show) {
      initFilters();
    }
  }, [props.show]);

  useEffect(() => {
    if(isError(
      filterData.percentage?.from,
      filterData.percentage?.to
    ) ||
    isError(
      filterData.recommendationsCount?.from,
      filterData.recommendationsCount?.to
    ) ||
    isError(
      filterData.errosCount?.from,
      filterData.errosCount?.to
    ) || 
    isError(
      filterData.hardwareCurrent?.from,
      filterData.hardwareCurrent?.to
    ) ||
    isError(
      filterData.reservationSavings?.from,
      filterData.reservationSavings?.to
    ) ||
    isError(
      filterData.reservationBreakEven?.from,
      filterData.reservationBreakEven?.to
    ) ||
    isError(
      filterData.licensingCurrent?.from,
      filterData.licensingCurrent?.to
    ) || 
    isError(
      filterData.licensingSavings?.from,
      filterData.licensingSavings?.to
    ) || 
    isError(
      filterData.licensingBreakEven?.from,
      filterData.licensingBreakEven?.to
    ) ||
    isError(
      filterData.diskSize?.from,
      filterData.diskSize?.to
    )) {
      setHasError(true);
    } else {
      setHasError(false);
    }
  }, [filterData]);

  const initFilters = () => {
    const filterLocationList: string[] = [];
    const filterSizeList: string[] = [];
    const filterOsList: string[] = [];
    (props?.resourceData || []).forEach((x: any) => {
      if (filterLocationList.indexOf(x?.location) === -1) {
        filterLocationList.push(x?.location);
      }
      const size = commonService.sizePipe(x.size || "");
      if (filterSizeList.indexOf(size) === -1) {
        filterSizeList.push(size);
      }
      if (filterOsList.indexOf(x?.operativeSystem) === -1) {
        filterOsList.push(x?.operativeSystem);
      }
    });
    setLocationList(filterLocationList);
    setSizeList(filterSizeList);
    setOsList(filterOsList);
  };

  const closeModal = () => {
    setOpen(false);
    props.closeModal();
  };

  const onInputChange = (key1: string, key2: string, value: any) => {
    const data = JSON.parse(JSON.stringify(filterData));
    data[key1][key2] = value;
    if(data[key1]?.type === 'value') {
      data[key1].filter = value?.toString()?.trim()?.length > 0;
    } else {
      const from = key2 === 'from' ? value: data[key1]?.from;
      const to = key2 === 'to' ? value: data[key1]?.to;
      data[key1].filter = !(from == 0 && to == data[key1]?.max);
    }
    setFilterData(data);
  };

  const filter = () => {
    props.refreshDetails(filterData);
    closeModal();
  };

  const clearFilters = () => {
    props.clearFilters();
    closeModal();
  };

  const isError = (min: string, max: string) => {
    return parseFloat(min || '0') > parseFloat(max || '0');
  };

  return (
    <ModalWrapper
      open={open}
      cancelButtonRef={cancelButtonRef}
      closeModal={closeModal}
    >
      <Dialog.Panel className="relative z-500 transform overflow-hidden rounded-lg bg-gray-100 px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full md:max-w-2xl lg:max-w-5xl sm:p-6">
                  <div>
                    <div className="mt-3 mb-10 sm:mt-5">
                      <Dialog.Title
                        as="h3"
                        className="text-base text-center font-semibold leading-6 text-gray-900"
                      >
                        Filter
                      </Dialog.Title>
                      <div className="mt-2 grid grid-cols-1 gap-x-6 gap-y-8 lg:grid-cols-2 xl:gap-x-8">
                        <div className="overflow-hidden rounded-xl border border-gray-200 bg-white shadow-md">
                          <div className="flex items-center gap-x-4 border-b border-gray-500/3 bg-white p-6">
                            <div className="text-sm font-medium leading-6 text-gray-900">
                              Name
                            </div>
                          </div>
                          <dl className="-my-3 divide-y divide-gray-100 px-6 py-4 text-sm leading-6">
                            <div>
                              <div>
                                <label
                                  htmlFor="name"
                                  className="block text-sm font-medium leading-6 text-gray-900"
                                >
                                  Name
                                </label>
                                <div className="mb-2">
                                  <input
                                    id="name"
                                    name="name"
                                    type="text"
                                    autoComplete="name"
                                    value={filterData.name.value}
                                    onChange={(e) =>
                                      onInputChange("name", "value", e?.target?.value)
                                    }
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                  />
                                </div>
                              </div>

                              <div>
                                <label
                                  htmlFor="location"
                                  className="block text-sm font-medium leading-6 text-gray-900"
                                >
                                  Location
                                </label>
                                <div className="mt-1 mb-4">
                                  <select
                                    id="location"
                                    name="location"
                                    className="block rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 [&_*]:text-black"
                                    value={filterData.location.value}
                                    onChange={(e) => {
                                      onInputChange(
                                        "location",
                                        "value",
                                        e?.target?.value
                                      );
                                    }}
                                  >
                                    <option value="" key="all">
                                      {allText}
                                    </option>
                                    {locationList.map((location: string) => (
                                      <option value={location} key={location}>
                                        {location}
                                      </option>
                                    ))}
                                  </select>
                                </div>
                              </div>

                              <div>
                                <label
                                  htmlFor="size"
                                  className="block text-sm font-medium leading-6 text-gray-900"
                                >
                                  Size
                                </label>
                                <div className="mt-1 mb-4">
                                  <select
                                    id="size"
                                    name="size"
                                    className="block rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 [&_*]:text-black"
                                    value={filterData.size.value}
                                    onChange={(e) => {
                                      onInputChange("size", "value", e?.target?.value);
                                    }}
                                  >
                                    <option value="" key="all">
                                      {allText}
                                    </option>
                                    {sizeList.map((size: string) => (
                                      <option value={size} key={size}>
                                        {size}
                                      </option>
                                    ))}
                                  </select>
                                </div>
                              </div>

                              <div>
                                <label
                                  htmlFor="os"
                                  className="block text-sm font-medium leading-6 text-gray-900"
                                >
                                  OS
                                </label>
                                <div className="mt-1 mb-4">
                                  <select
                                    id="os"
                                    name="os"
                                    className="block rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 [&_*]:text-black"
                                    value={filterData.os.value}
                                    onChange={(e) => {
                                      onInputChange("os", "value", e?.target?.value);
                                    }}
                                  >
                                    <option value="" key="all">
                                      {allText}
                                    </option>
                                    {osList.map((os: string) => (
                                      <option value={os} key={os}>
                                        {os}
                                      </option>
                                    ))}
                                  </select>
                                </div>
                              </div>
                            </div>
                          </dl>
                        </div>

                        <div className="overflow-hidden rounded-xl border border-gray-200 bg-white shadow-md">
                          <div className="flex items-center gap-x-4 border-b border-gray-500/3 bg-white p-6">
                            <div className="text-sm font-medium leading-6 text-gray-900">
                              Run Time Hours
                            </div>
                          </div>
                          <dl className="-my-3 divide-y divide-gray-100 px-6 py-4 text-sm leading-6">
                            <div>
                              <div>
                                <div className="flex justify-between">
                                  <div className="sm:col-span-3">
                                    <label
                                      htmlFor="percentageFrom"
                                      className="block text-sm font-medium leading-6 text-gray-900"
                                    >
                                      Percentage (Min):{" "}
                                      {filterData.percentage.from}
                                    </label>
                                    <div>
                                      <input
                                        type="range"
                                        name="percentageFrom"
                                        id="percentageFrom"
                                        className="block w-full py-1.5 text-gray-900"
                                        min="0"
                                        max={filterData?.percentage?.max || 0}
                                        value={filterData.percentage.from}
                                        onChange={(e) =>
                                          onInputChange(
                                            "percentage",
                                            "from",
                                            e?.target?.value
                                          )
                                        }
                                      />
                                    </div>
                                  </div>

                                  <div className="sm:col-span-3">
                                    <label
                                      htmlFor="percentageTo"
                                      className="block text-sm font-medium leading-6 text-gray-900"
                                    >
                                      Percentage (Max):{" "}
                                      {filterData.percentage.to}
                                    </label>
                                    <div>
                                      <input
                                        type="range"
                                        name="percentageTo"
                                        id="percentageTo"
                                        className="block w-full py-1.5 text-gray-900"
                                        min="0"
                                        max={filterData?.percentage?.max || 0}
                                        value={filterData.percentage.to}
                                        onChange={(e) =>
                                          onInputChange(
                                            "percentage",
                                            "to",
                                            e?.target?.value
                                          )
                                        }
                                      />
                                    </div>
                                  </div>
                                </div>
                                {hasError &&
                                  isError(
                                    filterData.percentage?.from,
                                    filterData.percentage?.to
                                  ) && (
                                    <p className="mb-2 text-sm text-red-600">
                                      {errorMsg}
                                    </p>
                                  )}
                              </div>

                              <div>
                                <div className="flex justify-between">
                                  <div className="sm:col-span-3">
                                    <label
                                      htmlFor="recommendationsFromCount"
                                      className="block text-sm font-medium leading-6 text-gray-900"
                                    >
                                      Recommendations (Min):{" "}
                                      {filterData.recommendationsCount?.from}
                                    </label>
                                    <div className="mb-2">
                                      <input
                                        type="range"
                                        name="recommendationsFromCount"
                                        id="recommendationsFromCount"
                                        className="block w-full py-1.5 text-gray-900"
                                        min="0"
                                        max={
                                          filterData?.recommendationsCount?.max ||
                                          0
                                        }
                                        value={
                                          filterData.recommendationsCount.from
                                        }
                                        onChange={(e) =>
                                          onInputChange(
                                            "recommendationsCount",
                                            "from",
                                            e?.target?.value
                                          )
                                        }
                                      />
                                    </div>
                                  </div>

                                  <div className="sm:col-span-3">
                                    <label
                                      htmlFor="recommendationsToCount"
                                      className="block text-sm font-medium leading-6 text-gray-900"
                                    >
                                      Recommendations (Max):{" "}
                                      {filterData.recommendationsCount?.to}
                                    </label>
                                    <div className="mb-2">
                                      <input
                                        type="range"
                                        name="recommendationsToCount"
                                        id="recommendationsToCount"
                                        className="block w-full py-1.5 text-gray-900"
                                        min="0"
                                        max={
                                          filterData.recommendationsCount?.max ||
                                          0
                                        }
                                        value={
                                          filterData.recommendationsCount.to
                                        }
                                        onChange={(e) =>
                                          onInputChange(
                                            "recommendationsCount",
                                            "to",
                                            e?.target?.value
                                          )
                                        }
                                      />
                                    </div>
                                  </div>
                                </div>
                                {hasError &&
                                  isError(
                                    filterData.recommendationsCount.from,
                                    filterData.recommendationsCount.to
                                  ) && (
                                    <p className="mb-2 text-sm text-red-600">
                                      {errorMsg}
                                    </p>
                                  )}
                              </div>

                              <div>
                                <div className="flex justify-between">
                                  <div className="sm:col-span-3">
                                    <label
                                      htmlFor="errosFromCount"
                                      className="block text-sm font-medium leading-6 text-gray-900"
                                    >
                                      Errors (Min): {filterData.errosCount?.from}
                                    </label>
                                    <div className="mb-2">
                                      <input
                                        type="range"
                                        name="errosFromCount"
                                        id="errosFromCount"
                                        className="block w-full py-1.5 text-gray-900"
                                        min="0"
                                        max={filterData?.errosCount?.max || 0}
                                        value={filterData.errosCount.from}
                                        onChange={(e) =>
                                          onInputChange(
                                            "errosCount",
                                            "from",
                                            e?.target?.value
                                          )
                                        }
                                      />
                                    </div>
                                  </div>

                                  <div className="sm:col-span-3">
                                    <label
                                      htmlFor="errosToCount"
                                      className="block text-sm font-medium leading-6 text-gray-900"
                                    >
                                      Errors (Max): {filterData.errosCount?.to}
                                    </label>
                                    <div className="mb-2">
                                      <input
                                        type="range"
                                        name="errosToCount"
                                        id="errosToCount"
                                        className="block w-full py-1.5 text-gray-900"
                                        min="0"
                                        max={filterData.errosCount?.max || 0}
                                        value={filterData.errosCount.to}
                                        onChange={(e) =>
                                          onInputChange(
                                            "errosCount",
                                            "to",
                                            e?.target?.value
                                          )
                                        }
                                      />
                                    </div>
                                  </div>
                                </div>
                                {hasError &&
                                  isError(
                                    filterData.errosCount?.from,
                                    filterData.errosCount?.to
                                  ) && (
                                    <p className="mb-2 text-sm text-red-600">
                                      {errorMsg}
                                    </p>
                                  )}
                              </div>
                            </div>
                          </dl>
                        </div>

                        <div className="overflow-hidden rounded-xl border border-gray-200 bg-white shadow-md">
                          <div className="flex items-center gap-x-4 border-b border-gray-500/3 bg-white p-6">
                            <div className="text-sm font-medium leading-6 text-gray-900">
                              Hardware
                            </div>
                          </div>
                          <dl className="-my-3 divide-y divide-gray-100 px-6 pt-4 pb-8 text-sm leading-6">
                            <div>
                              <div>
                                <div className="flex justify-between">
                                  <div className="sm:col-span-3">
                                    <label
                                      htmlFor="hardwareCurrentFrom"
                                      className="block text-sm font-medium leading-6 text-gray-900"
                                    >
                                      Current (Min):{" "}
                                      {filterData.hardwareCurrent?.from}
                                    </label>
                                    <div className="mb-2">
                                      <input
                                        type="range"
                                        name="hardwareCurrentFrom"
                                        id="hardwareCurrentFrom"
                                        className="block w-full py-1.5 text-gray-900"
                                        min="0"
                                        max={
                                          filterData.hardwareCurrent?.max || 0
                                        }
                                        value={filterData.hardwareCurrent.from}
                                        onChange={(e) =>
                                          onInputChange(
                                            "hardwareCurrent",
                                            "from",
                                            e?.target?.value
                                          )
                                        }
                                      />
                                    </div>
                                  </div>

                                  <div className="sm:col-span-3">
                                    <label
                                      htmlFor="hardwareCurrentTo"
                                      className="block text-sm font-medium leading-6 text-gray-900"
                                    >
                                      Current (Max):{" "}
                                      {filterData.hardwareCurrent?.to}
                                    </label>
                                    <div className="mb-2">
                                      <input
                                        type="range"
                                        name="hardwareCurrentTo"
                                        id="hardwareCurrentTo"
                                        className="block w-full py-1.5 text-gray-900"
                                        min="0"
                                        max={
                                          filterData.hardwareCurrent?.max || 0
                                        }
                                        value={filterData.hardwareCurrent.to}
                                        onChange={(e) =>
                                          onInputChange(
                                            "hardwareCurrent",
                                            "to",
                                            e?.target?.value
                                          )
                                        }
                                      />
                                    </div>
                                  </div>
                                </div>
                                {hasError &&
                                  isError(
                                    filterData.hardwareCurrent?.from,
                                    filterData.hardwareCurrent?.to
                                  ) && (
                                    <p className="mb-2 text-sm text-red-600">
                                      {errorMsg}
                                    </p>
                                  )}
                              </div>

                              <div>
                                <div className="flex justify-between">
                                  <div className="sm:col-span-3">
                                    <label
                                      htmlFor="reservationSavingsFrom"
                                      className="block text-sm font-medium leading-6 text-gray-900"
                                    >
                                      Reservation Savings (Min):{" "}
                                      {filterData.reservationSavings?.from}
                                    </label>
                                    <div className="mb-2">
                                      <input
                                        type="range"
                                        name="reservationSavingsFrom"
                                        id="reservationSavingsFrom"
                                        className="block w-full py-1.5 text-gray-900"
                                        min="0"
                                        max={
                                          filterData?.reservationSavings?.max ||
                                          0
                                        }
                                        value={
                                          filterData.reservationSavings.from
                                        }
                                        onChange={(e) =>
                                          onInputChange(
                                            "reservationSavings",
                                            "from",
                                            e?.target?.value
                                          )
                                        }
                                      />
                                    </div>
                                  </div>

                                  <div className="sm:col-span-3">
                                    <label
                                      htmlFor="reservationSavingsTo"
                                      className="block text-sm font-medium leading-6 text-gray-900"
                                    >
                                      Reservation Savings (Max):{" "}
                                      {filterData.reservationSavings?.to}
                                    </label>
                                    <div className="mb-2">
                                      <input
                                        type="range"
                                        name="reservationSavingsTo"
                                        id="reservationSavingsTo"
                                        className="block w-full py-1.5 text-gray-900"
                                        min="0"
                                        max={
                                          filterData?.reservationSavings?.max ||
                                          0
                                        }
                                        value={filterData.reservationSavings.to}
                                        onChange={(e) =>
                                          onInputChange(
                                            "reservationSavings",
                                            "to",
                                            e?.target?.value
                                          )
                                        }
                                      />
                                    </div>
                                  </div>
                                </div>
                                {hasError &&
                                  isError(
                                    filterData.reservationSavings?.from,
                                    filterData.reservationSavings?.to
                                  ) && (
                                    <p className="mb-2 text-sm text-red-600">
                                      {errorMsg}
                                    </p>
                                  )}
                              </div>

                              <div>
                                <div className="flex justify-between">
                                  <div className="sm:col-span-3">
                                    <label
                                      htmlFor="reservationBreakEvenFrom"
                                      className="block text-sm font-medium leading-6 text-gray-900"
                                    >
                                      Reservation BreakEven (Min):{" "}
                                      {filterData.reservationBreakEven?.from}
                                    </label>
                                    <div className="mb-2">
                                      <input
                                        type="range"
                                        name="reservationBreakEvenFrom"
                                        id="reservationBreakEvenFrom"
                                        className="block w-full py-1.5 text-gray-900"
                                        min="0"
                                        max={
                                          filterData?.reservationBreakEven?.max ||
                                          0
                                        }
                                        value={
                                          filterData.reservationBreakEven.from
                                        }
                                        onChange={(e) =>
                                          onInputChange(
                                            "reservationBreakEven",
                                            "from",
                                            e?.target?.value
                                          )
                                        }
                                      />
                                    </div>
                                  </div>

                                  <div className="sm:col-span-3">
                                    <label
                                      htmlFor="reservationBreakEvenTo"
                                      className="block text-sm font-medium leading-6 text-gray-900"
                                    >
                                      Reservation BreakEven (Max):{" "}
                                      {filterData.reservationBreakEven?.to}
                                    </label>
                                    <div className="mb-2">
                                      <input
                                        type="range"
                                        name="reservationBreakEvenTo"
                                        id="reservationBreakEvenTo"
                                        className="block w-full py-1.5 text-gray-900"
                                        min="0"
                                        max={
                                          filterData?.reservationBreakEven?.max ||
                                          0
                                        }
                                        value={
                                          filterData.reservationBreakEven.to
                                        }
                                        onChange={(e) =>
                                          onInputChange(
                                            "reservationBreakEven",
                                            "to",
                                            e?.target?.value
                                          )
                                        }
                                      />
                                    </div>
                                  </div>
                                </div>
                                {hasError &&
                                  isError(
                                    filterData.reservationBreakEven?.from,
                                    filterData.reservationBreakEven?.to
                                  ) && (
                                    <p className="mb-2 text-sm text-red-600">
                                      {errorMsg}
                                    </p>
                                  )}
                              </div>
                            </div>
                          </dl>
                        </div>

                        <div className="overflow-hidden rounded-xl border border-gray-200 bg-white shadow-md">
                          <div className="flex items-center gap-x-4 border-b border-gray-500/3 bg-white p-6">
                            <div className="text-sm font-medium leading-6 text-gray-900">
                              Licensing
                            </div>
                          </div>
                          <dl className="-my-3 divide-y divide-gray-100 px-6 pt-4 pb-8 text-sm leading-6">
                            <div>
                              <div>
                                <div className="flex justify-between">
                                  <div className="sm:col-span-3">
                                    <label
                                      htmlFor="licensingCurrentFrom"
                                      className="block text-sm font-medium leading-6 text-gray-900"
                                    >
                                      Current (Min):{" "}
                                      {filterData.licensingCurrent?.from}
                                    </label>
                                    <div className="mb-2">
                                      <input
                                        type="range"
                                        name="licensingCurrentFrom"
                                        id="licensingCurrentFrom"
                                        className="block w-full py-1.5 text-gray-900"
                                        min="0"
                                        max={
                                          filterData?.licensingCurrent?.max ||
                                          0
                                        }
                                        value={filterData.licensingCurrent.from}
                                        onChange={(e) =>
                                          onInputChange(
                                            "licensingCurrent",
                                            "from",
                                            e?.target?.value
                                          )
                                        }
                                      />
                                    </div>
                                  </div>

                                  <div className="sm:col-span-3">
                                    <label
                                      htmlFor="licensingCurrentTo"
                                      className="block text-sm font-medium leading-6 text-gray-900"
                                    >
                                      Current (Max):{" "}
                                      {filterData.licensingCurrent?.to}
                                    </label>
                                    <div className="mb-2">
                                      <input
                                        type="range"
                                        name="licensingCurrentTo"
                                        id="licensingCurrentTo"
                                        className="block w-full py-1.5 text-gray-900"
                                        min="0"
                                        max={
                                          filterData?.licensingCurrent?.max ||
                                          0
                                        }
                                        value={filterData.licensingCurrent.to}
                                        onChange={(e) =>
                                          onInputChange(
                                            "licensingCurrent",
                                            "to",
                                            e?.target?.value
                                          )
                                        }
                                      />
                                    </div>
                                  </div>
                                </div>
                                {hasError &&
                                  isError(
                                    filterData.licensingCurrent?.from,
                                    filterData.licensingCurrent?.to
                                  ) && (
                                    <p className="mb-2 text-sm text-red-600">
                                      {errorMsg}
                                    </p>
                                  )}
                              </div>

                              <div>
                                <div className="flex justify-between">
                                  <div className="sm:col-span-3">
                                    <label
                                      htmlFor="licensingSavingsFrom"
                                      className="block text-sm font-medium leading-6 text-gray-900"
                                    >
                                      Purchase Savings (Min):{" "}
                                      {filterData.licensingSavings?.from}
                                    </label>
                                    <div className="mb-2">
                                      <input
                                        type="range"
                                        name="licensingSavingsFrom"
                                        id="licensingSavingsFrom"
                                        className="block w-full py-1.5 text-gray-900"
                                        min="0"
                                        max={
                                          filterData?.licensingSavings?.max ||
                                          0
                                        }
                                        value={filterData.licensingSavings.from}
                                        onChange={(e) =>
                                          onInputChange(
                                            "licensingSavings",
                                            "from",
                                            e?.target?.value
                                          )
                                        }
                                      />
                                    </div>
                                  </div>

                                  <div className="sm:col-span-3">
                                    <label
                                      htmlFor="licensingSavingsTo"
                                      className="block text-sm font-medium leading-6 text-gray-900"
                                    >
                                      Purchase Savings (Max):{" "}
                                      {filterData.licensingSavings?.to}
                                    </label>
                                    <div className="mb-2">
                                      <input
                                        type="range"
                                        name="licensingSavingsTo"
                                        id="licensingSavingsTo"
                                        className="block w-full py-1.5 text-gray-900"
                                        min="0"
                                        max={
                                          filterData?.licensingSavings?.max ||
                                          0
                                        }
                                        value={filterData.licensingSavings.to}
                                        onChange={(e) =>
                                          onInputChange(
                                            "licensingSavings",
                                            "to",
                                            e?.target?.value
                                          )
                                        }
                                      />
                                    </div>
                                  </div>
                                </div>
                                {hasError &&
                                  isError(
                                    filterData.licensingSavings?.from,
                                    filterData.licensingSavings?.to
                                  ) && (
                                    <p className="mb-2 text-sm text-red-600">
                                      {errorMsg}
                                    </p>
                                  )}
                              </div>

                              <div>
                                <div className="flex justify-between">
                                  <div className="sm:col-span-3">
                                    <label
                                      htmlFor="licensingBreakEvenFrom"
                                      className="block text-sm font-medium leading-6 text-gray-900"
                                    >
                                      Purchase BreakEven (Min):{" "}
                                      {filterData.licensingBreakEven?.from}
                                    </label>
                                    <div className="mb-2">
                                      <input
                                        type="range"
                                        name="licensingBreakEvenFrom"
                                        id="licensingBreakEvenFrom"
                                        className="block w-full py-1.5 text-gray-900"
                                        min="0"
                                        max={
                                          filterData?.licensingBreakEven?.max ||
                                          0
                                        }
                                        value={
                                          filterData.licensingBreakEven.from
                                        }
                                        onChange={(e) =>
                                          onInputChange(
                                            "licensingBreakEven",
                                            "from",
                                            e?.target?.value
                                          )
                                        }
                                      />
                                    </div>
                                  </div>

                                  <div className="sm:col-span-3">
                                    <label
                                      htmlFor="licensingBreakEvenTo"
                                      className="block text-sm font-medium leading-6 text-gray-900"
                                    >
                                      Purchase BreakEven (Max):{" "}
                                      {filterData.licensingBreakEven?.to}
                                    </label>
                                    <div className="mb-2">
                                      <input
                                        type="range"
                                        name="licensingBreakEvenTo"
                                        id="licensingBreakEvenTo"
                                        className="block w-full py-1.5 text-gray-900"
                                        min="0"
                                        max={
                                          filterData?.licensingBreakEven?.max ||
                                          0
                                        }
                                        value={filterData.licensingBreakEven.to}
                                        onChange={(e) =>
                                          onInputChange(
                                            "licensingBreakEven",
                                            "to",
                                            e?.target?.value
                                          )
                                        }
                                      />
                                    </div>
                                  </div>
                                </div>
                                {hasError &&
                                  isError(
                                    filterData.licensingBreakEven?.from,
                                    filterData.licensingBreakEven?.to
                                  ) && (
                                    <p className="mb-2 text-sm text-red-600">
                                      {errorMsg}
                                    </p>
                                  )}
                              </div>


                              <div>
                                <label
                                  htmlFor="ahbuStatus"
                                  className="block text-sm font-medium leading-6 text-gray-900"
                                >
                                  AHBU Status
                                </label>
                                <div className="mt-1 mb-4">
                                  <select
                                    id="ahbuStatus"
                                    name="ahbuStatus"
                                    className="block rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 [&_*]:text-black"
                                    value={filterData.ahbuStatus.value}
                                    onChange={(e) => {
                                      onInputChange(
                                        "ahbuStatus",
                                            "value",
                                        e?.target?.value
                                      );
                                    }}
                                  >
                                    <option value="" key="all">
                                      {allText}
                                    </option>
                                    <option value="enabled" key="enabled">
                                      Enabled
                                    </option>
                                    <option value="disabled" key="disabled">
                                      Disabled
                                    </option>
                                  </select>
                                </div>
                              </div>


                            </div>
                          </dl>
                        </div>

                        <div className="overflow-hidden rounded-xl border border-gray-200 bg-white shadow-md">
                          <div className="flex items-center gap-x-4 border-b border-gray-500/3 bg-white p-6">
                            <div className="text-sm font-medium leading-6 text-gray-900">
                              Disk
                            </div>
                          </div>
                          <dl className="-my-3 divide-y divide-gray-100 px-6 pt-4 pb-8 text-sm leading-6">
                            <div>
                              <div>
                                <label
                                  htmlFor="diskType"
                                  className="block text-sm font-medium leading-6 text-gray-900"
                                >
                                  Type
                                </label>
                                <div className="mt-1 mb-4">
                                  <select
                                    id="diskType"
                                    name="diskType"
                                    className="block rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 [&_*]:text-black"
                                    value={filterData.diskType.value}
                                    onChange={(e) => {
                                      onInputChange(
                                        "diskType",
                                            "value",
                                        e?.target?.value
                                      );
                                    }}
                                  >
                                    <option value="" key="all">
                                      {allText}
                                    </option>
                                    <option value="os" key="os">
                                      OS Disk
                                    </option>
                                    <option value="data" key="data">
                                      Data Disk
                                    </option>
                                  </select>
                                </div>
                              </div>

                              <div>
                                <div className="flex justify-between">
                                  <div className="sm:col-span-3">
                                    <label
                                      htmlFor="diskSizeFrom"
                                      className="block text-sm font-medium leading-6 text-gray-900"
                                    >
                                      Size (Min): {filterData.diskSize?.from}
                                    </label>
                                    <div className="mb-2">
                                      <input
                                        type="range"
                                        name="diskSizeFrom"
                                        id="diskSizeFrom"
                                        className="block w-full py-1.5 text-gray-900"
                                        min="0"
                                        max={filterData?.diskSize?.max || 0}
                                        value={filterData.diskSize.from}
                                        onChange={(e) =>
                                          onInputChange(
                                            "diskSize",
                                            "from",
                                            e?.target?.value
                                          )
                                        }
                                      />
                                    </div>
                                  </div>

                                  <div className="sm:col-span-3">
                                    <label
                                      htmlFor="diskSizeTo"
                                      className="block text-sm font-medium leading-6 text-gray-900"
                                    >
                                      Size (Max): {filterData.diskSize?.to}
                                    </label>
                                    <div className="mb-2">
                                      <input
                                        type="range"
                                        name="diskSizeTo"
                                        id="diskSizeTo"
                                        className="block w-full py-1.5 text-gray-900"
                                        min="0"
                                        max={filterData?.diskSize?.max || 0}
                                        value={filterData.diskSize.to}
                                        onChange={(e) =>
                                          onInputChange(
                                            "diskSize",
                                            "to",
                                            e?.target?.value
                                          )
                                        }
                                      />
                                    </div>
                                  </div>
                                </div>
                                {hasError &&
                                  isError(
                                    filterData.diskSize?.from,
                                    filterData.diskSize?.to
                                  ) && (
                                    <p className="mb-2 text-sm text-red-600">
                                      {errorMsg}
                                    </p>
                                  )}
                              </div>
                            </div>
                          </dl>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-3 sm:gap-3">
                    {!hasError && <button
                      type="submit"
                      className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:col-start-3"
                      onClick={() => filter()}
                    >
                      Filter
                    </button>}
                    <button
                      type="button"
                      className="inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-2 sm:mt-0"
                      onClick={() => clearFilters()}
                    >
                      Clear All Filters
                    </button>
                    <button
                      type="button"
                      className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
                      onClick={() => closeModal()}
                      ref={cancelButtonRef}
                    >
                      Cancel
                    </button>
                  </div>
                </Dialog.Panel>
    </ModalWrapper>
  );
}
