import { useEffect, useRef, useState } from "react";
import { Dialog } from "@headlessui/react";
import { CodeGroup } from "../../documents";
import { ModalWrapper } from "../../common/modalWrapper";
interface Props {
  show: boolean;
  closeModal: (refresh?: boolean) => void;
}
export default function LinkPartnerIdModal(
  props: Readonly<Props>
) {
  const [open, setOpen] = useState(props.show);
  const cancelButtonRef = useRef(null);

  useEffect(() => {
    setOpen(props.show);
  }, [props?.show]);

  const closeModal = (refresh?: boolean) => {
    setOpen(false);
    props.closeModal(refresh);
  };

  return (
    <ModalWrapper
      open={open}
      cancelButtonRef={cancelButtonRef}
      closeModal={closeModal}
    >
      <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full md:max-w-2xl lg:max-w-5xl sm:p-6">
        <div>
          <div className="mt-3 sm:mt-5">
            <Dialog.Title
              as="h3"
              className="text-base text-center font-semibold leading-6 text-gray-900"
            >
              Link Partner ID
            </Dialog.Title>
            <div className="mt-2 p-4">
              <div className="my-6">
                <h3>Option A:</h3>
                <p className="text-sm text-gray-700 mt-1 mb-3">
                  Run the following code on the CLI
                </p>
                <CodeGroup title="Azure CLI" code={`az extension add --name managementpartner\n\naz managementpartner create --partner-id 6816279`} tag="">
                  <span>
                    <p>az extension add --name managementpartner</p>
                    <br />
                    <p>az managementpartner create --partner-id 6816279</p>
                  </span>
                </CodeGroup>
              </div>

              <div className="my-6">
                <h3>Option B:</h3>
                <p className="text-sm text-gray-700 mt-1 mb-3">
                    Use the Azure portal to link to a new partner ID
                </p>
                <p className="text-sm text-gray-700 mt-1 mb-3">
                    1. Go to <a
                            href="https://portal.azure.com/#blade/Microsoft_Azure_Billing/managementpartnerblade"
                            target={"_blank"}
                            className="font-semibold text-indigo-600 hover:text-indigo-500"
                        >
                            Link to a partner ID
                        </a> in the Azure portal.</p>
                    <p className="text-sm text-gray-700 mt-1 mb-3">2. Sign in to the Azure portal.</p>
                    <p className="text-sm text-gray-700 mt-1 mb-3">3. Enter the Microsoft partner ID. The partner ID is the <a
                            href="https://partner.microsoft.com/"
                            target={"_blank"}
                            className="font-semibold text-indigo-600 hover:text-indigo-500"
                        >Microsoft Cloud Partner Program
                        </a> ID for your organization. Be sure to use the Associated Partner ID shown on your partner profile.</p>
                        
                        <img src="https://learn.microsoft.com/en-us/azure/cost-management-billing/manage/media/link-partner-id/link-partner-id01.png" />
                        <br />
                        
                    <p className="text-sm text-gray-700 mt-1 mb-3">4. To link a partner ID for another customer, switch the directory. Under Switch directory, select your directory.</p> 
                    <img src="https://learn.microsoft.com/en-us/azure/cost-management-billing/manage/media/link-partner-id/directory-switcher.png" />
                
              </div>
            </div>
          </div>
        </div>

        <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
          <button
            type="button"
            className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-2 sm:mt-0"
            onClick={() => closeModal()}
            ref={cancelButtonRef}
          >
            Cancel
          </button>
        </div>
      </Dialog.Panel>
    </ModalWrapper>
  );
}
